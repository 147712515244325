import React from "react";
import HotelCategoryImages from "../components/Hotel/HotelCategories/CategoriesTable";
import Box from "@mui/material/Box";
import HotelTabs from "../components/Hotel/Tabs";

export default function HotelCategoryPage() {
    return (
        <Box sx={{ width: "100%" }}>
            <HotelTabs />
            <HotelCategoryImages />
        </Box>
    );
}
