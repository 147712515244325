import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Table from "./companiesTable";
import AddCompany from "./AddCompany";
import "./style.css";
import MessageProvider from "./Context";

const Company = () => {
    const [isWaiting, setIsWaiting] = useState(false);
    return (
        <div className="company">
            {isWaiting && <div className="ca-overlay"></div>}
            <MessageProvider>
                <Table setIsWaiting={setIsWaiting} />
                <AddCompany />
            </MessageProvider>
        </div>
    );
};

export default Company;
