import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import UpdateHotel from "./UpdateHotel";
import GenerateEmail from "./GenerateEmail";
import RegenerateEmail from "./RegenerateEmail";
import Block from "./HotelBlocking";
import Reactivate from "./HotelActivation";
import StatusChangeMember from "./statusChangeMember";
import OpenOnBooking from "./OpenOnBooking";
import GenerateLicense from "./GenerateLicense";
import GenerateLicensePartner from "./GenerateLicensePartner";
import ActivityHotel from "./ActivityHotel";

export default function AccountMenu({ id, status, in_hbp, name, partner, data, setIsWaiting, getAllHotels }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        sessionStorage.setItem("hotelname", name);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ textAlign: "center" }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem>
                    <UpdateHotel data={data} id={id} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                </MenuItem>
                <MenuItem disabled={status !== 1&&status !== 2&&status !== 3}>
                    <ActivityHotel id={id} in_hbp={in_hbp} name={name} setIsWaiting={setIsWaiting} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                </MenuItem>
                {status !== 3 ? (
                    <MenuItem disabled={status !== 1}>
                        <GenerateEmail id={id} setIsWaiting={setIsWaiting} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                    </MenuItem>
                ) : (
                    <MenuItem>
                        <RegenerateEmail id={id} setIsWaiting={setIsWaiting} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                    </MenuItem>
                )}
                {partner === "member" && (
                    <MenuItem disabled={status !== 4}>
                        <GenerateLicense id={id} setIsWaiting={setIsWaiting} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                    </MenuItem>
                )}
                {partner === "partner" && (
                    <MenuItem disabled={status !== 4}>
                        <GenerateLicensePartner id={id} setIsWaiting={setIsWaiting} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                    </MenuItem>
                )}
                <MenuItem disabled={status !== 4}>
                    <OpenOnBooking id={id} in_hbp={in_hbp} name={name} setIsWaiting={setIsWaiting} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                </MenuItem>

                <MenuItem disabled={status !== 6}>
                    <StatusChangeMember id={id} setIsWaiting={setIsWaiting} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                </MenuItem>
                <MenuItem disabled={status !== 4}>
                    <Block id={id} setIsWaiting={setIsWaiting} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                </MenuItem>
                <MenuItem disabled={status !== 5}>
                    <Reactivate id={id} setIsWaiting={setIsWaiting} getAllHotels={getAllHotels} handleMenuClose={handleClose} />
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
