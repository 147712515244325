import React from "react";
import Hotel from "../components/Hotel";

const HotelPage = () => {
    return (
        <>
            <Hotel />
        </>
    );
};

export default HotelPage;
