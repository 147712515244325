import React from "react";
import Countries from "../components/common/countries";

const CountriesPage = () => {
    return (
        <>
            <Countries />
        </>
    );
};

export default CountriesPage;
