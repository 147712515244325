import React from "react";
import Booking from "../components/Booking/index";

const BookingPage = () => {
    return (
        <>
            <Booking />
        </>
    );
};

export default BookingPage;
