import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import ENV from "../../../environment";
import Spinner from "react-spinner-material";
import { useParams } from "react-router-dom";
import { text } from "tts-hrp-val-lib";
import { Checkbox } from "antd";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ getTableData, category, close }) {
    const { hotelid } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [share, setShare] = useState(false);
    const onChange = (e) => setShare(e.target.checked);

    useEffect(() => {
        setShare(category.is_shared);
        setData(category);
    }, [category]);

    const validate = Yup.object({
        name: Yup.string().required("category name is required").matches(text, { message: "invalid value" }),
        description: Yup.string().optional("description is required").nullable().matches(text, { message: "invalid value" }),
        number_of_people: Yup.string().required("Field is required"),
        price: Yup.number().required("Field is required"),
    });

    var emptyData = data;

    const onUpdate = async (values) => {
        setLoading(true);
        const body = {
            id: values?.id,
            name: values?.name,
            description: values?.description || null,
            price: values?.price,
            number_of_people: values?.number_of_people,
            is_shared: share
        };
        await axios
            .put(`${ENV.URL}/api/hdp/tts/adm/hotel/category/${hotelid}`, body, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => {
                toast.success("Updated successfully", { autoClose: 2000 });
                handleClose();
                close();
                getTableData();
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
            });
    };

    return (
        <div>
            <Typography onClick={handleOpen} title="Click">
                Update
            </Typography>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    {data === emptyData && (
                        <Box sx={style} className="modal_res">
                            <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                                Update category info
                            </Typography>

                            <Formik
                                initialValues={data}
                                validationSchema={validate}
                                onSubmit={async (values) => {
                                    onUpdate(values);
                                }}
                            >
                                {({ values, errors }) => (
                                    <Form autoComplete="off" className="mt-4">
                                        <>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <Field fullWidth name="name" size="small" component={TextField} label={"Name"} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field fullWidth name="description" size="small" component={TextField} label={"Description"} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field fullWidth name="price" size="small" component={TextField} label={"Price"} type="number" />
                                                </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                fullWidth
                                                name="number_of_people"
                                                size="small"
                                                component={TextField}
                                                label={"Number of people"}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Checkbox defaultChecked={share} onChange={onChange}>{"shared"}</Checkbox>
                                        </Grid>
                                            </Grid>
                                        </>
                                        <div className="right_left">
                                            <Button variant="contained" color="error" size="small" sx={{ mt: 3, mb: 2 }} onClick={handleClose}>
                                                Close
                                            </Button>
                                            <>
                                                {loading ? (
                                                    <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        Submit
                                                    </LoadingButton>
                                                ) : (
                                                    <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        Submit
                                                    </Button>
                                                )}
                                            </>
                                        </div>
                                        {loading && <div className="overlay" />}
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    )}
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
