import React from "react";
import Box from "@mui/material/Box";
import HotelCategoryTabs from "../components/Hotel/HotelCategories/Tabs";
import HotelCategoryImages from "../components/Hotel/HotelCategories/Pictures";

export default function HotelCategoryImagePage() {
    return (
        <Box sx={{ width: "100%" }}>
            <HotelCategoryTabs />
            <HotelCategoryImages />
        </Box>
    );
}
