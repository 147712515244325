/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Message } from "./Context";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import Spinner from "react-spinner-material";
import REACT_APP from "../../../environment";
import axios from "axios";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function UpdateAminity({ id, amenityGroups, handleMenuClose, getAllHotels }) {
    const [data, setData] = useState();
    const [, setMessage] = useContext(Message);
    const [loading, setLoading] = React.useState(false);
    const [wait, setWait] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getData = async () => {
        setWait(true);
        await axios
            .get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/category/amenity/${id}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((res) => {
                setWait(false);
                setData(res.data.data);
            })
            .catch((err) => {
                toast.error("An error occurred");
                setWait(false);
                console.log(err.message);
            });
    };

    const validation = Yup.object({
        name: Yup.string().required("Name is required").matches(regex.text, { message: "Enter valid name" }),
        comment: Yup.string().nullable(),
        group: Yup.string().required("field is required"),
    });

    const emptyData = data;

    const onUpdate = async (values) => {
        const data = { name: values.name, comment: values?.comment || "", group: values.group };
        setMessage(true);
        setLoading(true);
        await axios
            .put(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/category/amenity/${values.id}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then(async (res) => {
                await getAllHotels();
                setLoading(false);
                setMessage(false);
                toast.success("Success!", { autoClose: 2000 });
                handleClose();
                handleMenuClose();
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error("An error occurred");
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="addhotel">
            <Button onClick={handleOpen}>Update</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            Enter categories of amenities information
                        </Typography>
                        <Formik
                            initialValues={emptyData}
                            validationSchema={validation}
                            onSubmit={async (values) => {
                                onUpdate(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    {wait ? (
                                        <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                                        </div>
                                    ) : (
                                        <>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        label="Amenity name"
                                                        name="name"
                                                        component={TextField}
                                                        autoComplete="password"
                                                        type="text"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className="marginBottom">
                                                <Field fullWidth name="comment" label="Comment" autoComplete="comment" component={TextField} />
                                            </Grid>{" "}
                                            <Grid item xs={12} className="marginBottom">
                                                <Field fullWidth select name="group" component={TextField} label={"Amenity group"}>
                                                    {amenityGroups.map((item, index) => (
                                                        <MenuItem value={item.id} key={index}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                            </Grid>
                                            <div className="left_right">
                                                <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                                    Close
                                                </Button>
                                                {loading ? (
                                                    <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        Update
                                                    </LoadingButton>
                                                ) : (
                                                    <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        Update
                                                    </Button>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
