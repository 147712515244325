/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Spinner from "react-spinner-material";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import axios from "axios";
import REACT_APP from "../../../environment.js";
import { useParams } from "react-router-dom";
import styles from "./styles.module.css";
import ProviderAlert from "./Alert.jsx";
import ProviderContents from "./Contents.jsx";
import ProviderContacts from "./Contact.jsx";
import ProviderProducts from "./Product.jsx";
import ProviderSettings from "./Settings.jsx";

const Provider = () => {
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [isWaiting, setIsWaiting] = useState(false);
    const [data, setData] = React.useState({});
    const { id } = useParams();

    const getData = async () => {
        setLoading(true);
        await axios
            .get(`${REACT_APP.URL}/api/hrp/tts/adm/provider/${id}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
    };
    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div className={styles.container}>
            {isWaiting && <div className="ca-overlay"></div>}
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : error ? (
                <Alert variant="outlined" severity="error">
                    An error occurred
                </Alert>
            ) : (
                <React.Fragment>
                    <ProviderAlert data={data} getData={getData} setIsWaiting={setIsWaiting} />
                    <ProviderContents data={data} />
                    <ProviderContacts data={data} />
                    {/* <ProviderProducts data={data} /> */}
                    <ProviderSettings data={data} getData={getData} setIsWaiting={setIsWaiting} />
                </React.Fragment>
            )}
        </div>
    );
};

export default Provider;
