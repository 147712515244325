import React from "react";
import University from "../components/University";

const UniversityPage = () => {
    return (
        <>
            <University />
        </>
    );
};

export default UniversityPage;
