/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Message } from "./Context";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import REACT_APP from "../../../environment";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function UpdateRegions({ id, data, handleMenuClose, getAllHotels, searchValue, countries }) {
    const [, setMessage] = useContext(Message);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validation = Yup.object({
        name: Yup.string().required("Name is required").matches(/^[\p{L}'`]+$/u, { message: "Enter valid name" }),
        native_name: Yup.string().required("Native name is required").matches(/^[\p{L}'`]+$/u, { message: "Enter valid native name" }),
        uzb_name: Yup.string().required("Uzbek name is required").matches(/^[\p{L}'`]+$/u, { message: "Enter valid uzbek name" })
    });

    const onUpdate = async (values) => {
        const updateData = { id: values.id, name: values.name, native_name: values.native_name, uzb_name: values.uzb_name };
        setMessage(true);
        try {
            await axios.put(`${REACT_APP.COMMON_API}/tts/api/v01/countries/${id}`, updateData, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.COMMON_ID, "secret": REACT_APP.COMMON_SECRET },
            });
            setMessage(false);
            toast.success("Success!", { autoClose: 2000 });
            getAllHotels();
            handleClose();
            handleMenuClose();
        } catch (err) {
            setLoading(false);
            console.error(err);
            toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
        }
    };

    const keyUp = (e) => {
        console.log(e.keyCode, 1);
    };

    useEffect(() => {
        document.addEventListener("keyup", keyUp);
        return () => {
            document.removeEventListener("keyup", keyUp);
        };
    }, []);

    return (
        <div>
            <Button onClick={handleOpen}>Update</Button>
            <Modal
                aria-labelledby="transition-modal-title-2"
                aria-describedby="transition-modal-description-2"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title-2" variant="h6" component="h2" className="marginBottom">
                            Enter country information
                        </Typography>
                        <Formik initialValues={data} enableReinitialize validationSchema={validation} >
                            {({ values, errors, dirty }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field fullWidth label="Name" name="name" component={TextField} type="text" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field fullWidth label="Native name" name="native_name" component={TextField} type="text" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field fullWidth label="Uzbek name" name="uzb_name" component={TextField} type="text" />
                                        </Grid>
                                    </Grid>
                                    <div className="left_right">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                            Close
                                        </Button>
                                        {loading ? (
                                            <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Update
                                            </LoadingButton>
                                        ) : (
                                            <Button onClick={() => onUpdate(values)} className="mt-5" disabled={!dirty || errors?.name || errors?.native_name || errors?.uzb_name} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Update
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
//fix bugs add and update and check all