import React from "react";
import HotelPictures from "../components/Hotel/HotelImages/Pictures";
import Box from "@mui/material/Box";
import HotelTabs from "../components/Hotel/Tabs";

export default function HotelImagePage() {
    return (
        <Box sx={{ width: "100%" }}>
            <HotelTabs />
            <HotelPictures />
        </Box>
    );
}
