/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import Spinner from "react-spinner-material";
import REACT_APP from "../../../environment";
import axios from "axios";
import regex from "tts-hrp-val-lib";

//     2024-03-05T11:39:52.036791+00:00 app[web.1]: {
//     2024-03-05T11:39:52.037131+00:00 app[web.1]:   message: 'something went wrong',
//     2024-03-05T11:39:52.037133+00:00 app[web.1]:   status: 'Unknown error',
//     2024-03-05T11:39:52.037134+00:00 app[web.1]:   errors: [
//     2024-03-05T11:39:52.037134+00:00 app[web.1]:     {
//     2024-03-05T11:39:52.037135+00:00 app[web.1]:       message: 'insert into "edu_form_items" ("current", "degree", "edu_form_id", "enrollment_date", "graduation_date", "leave_date", "major", "not_completed_reason", "provider_id") values ($1, $2, $3, $4, $5, $6, $7, $8, $9) - column "current" of relation "edu_form_items" does not exist',
//     2024-03-05T11:39:52.037137+00:00 app[web.1]:       code: 'cmn_error'
//     2024-03-05T11:39:52.037137+00:00 app[web.1]:     }
//     2024-03-05T11:39:52.037137+00:00 app[web.1]:   ]
//     2024-03-05T11:39:52.037137+00:00 app[web.1]: }

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function UpdateProvider({ data, getData, setIsWaiting }) {
    const [emptyData, setEmptyData] = React.useState({});
    const validateCompany = Yup.object({
        name: Yup.string().required("Name is required"),
        email: Yup.string().required("Email is required"),
        website: Yup.string().matches(regex.website, "Enter correct url!"),
    });

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onUpdate = async (data, setSubmitting) => {
        setIsWaiting(true);
        await axios
            .put(`${REACT_APP.URL}/api/hrp/tts/adm/provider`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then(async () => {
                toast.success("Success!", { autoClose: 2000 });
                handleClose();
                await getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred");
            });
        setIsWaiting(false);
        setSubmitting(false);
    };
    useEffect(() => {
        if (data) setEmptyData({ id: data.id, name: data.name, email: data.email, website: data.website || undefined });
    }, []);
    return (
        <div className="addhotel">
            <Button onClick={handleOpen} variant="outlined">
                Update
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            Enter provider information
                        </Typography>
                        <Formik initialValues={emptyData} validationSchema={validateCompany} onSubmit={onUpdate}>
                            {({ values, errors, isSubmitting }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field fullWidth label="Company name" name="name" component={TextField} type="text" />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth name="email" label="Company email" component={TextField} />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth label="Company website (optional)" name="website" component={TextField} type="text" />
                                    </Grid>
                                    <div className="left_right">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                            Close
                                        </Button>
                                        {isSubmitting ? (
                                            <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Submit
                                            </LoadingButton>
                                        ) : (
                                            <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
