import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";

export default function OpenOnBooking({ id, setIsWaiting, in_hbp, name, getAllHotels, handleMenuClose }) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const openOperation = async () => {
        handleClose();
        setIsWaiting(true);
        await axios
            .put(
                `${REACT_APP.URL}/api/hdp/tts/adm/hotel/view_hbp/${id}`,
                { in_hbp: true },
                { headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET } }
            )
            .then(() => {
                setIsWaiting(false);
                toast.success("Success", { autoClose: 2000 });
                handleMenuClose();
                getAllHotels();
            })
            .catch((err) => {
                setIsWaiting(false);
                handleMenuClose();
                console.log(err);
                toast.error(err?.response?.data?.errors[0]?.message || "Something went wrong");
            });
    };

    const closeOperation = async () => {
        handleClose();
        setIsWaiting(true);
        await axios
            .put(
                `${REACT_APP.URL}/api/hdp/tts/adm/hotel/view_hbp/${id}`,
                { in_hbp: false },
                { headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET } }
            )
            .then(() => {
                setIsWaiting(false);
                toast.success("Success", { autoClose: 2000 });
                handleMenuClose();
                getAllHotels();
            })
            .catch((err) => {
                setIsWaiting(false);
                handleMenuClose();
                console.log(err);
                toast.error(err?.response?.data?.errors[0]?.message || "Something went wrong");
            });
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>{in_hbp ? "Remove booking" : "Add booking"}</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{in_hbp ? "Remove booking" : "Add booking"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {in_hbp
                            ? `After your confirmation, ${name} hotel will be enabled in a booking application`
                            : `After your confirmation, ${name} hotel will be disabled in a booking application`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={in_hbp ? closeOperation : openOperation}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
