/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import Spinner from "react-spinner-material";
import REACT_APP from "../../../environment";
import axios from "axios";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function SetProviderBankInfo({ data, getData, setIsWaiting }) {
    const [emptyData, setEmptyData] = React.useState({});
    const validateCompany = Yup.object({
        TIN: Yup.string().matches(regex.TIN, { message: "Invalid TIN" }).required("TIN is required"),
        qqs_register_code: Yup.string().matches(regex.qqs_register_code, { message: "Invalid qqs register code" }).required("qqs register code is required"),
        bank_account: Yup.string().matches(regex.bank_account, { message: "Invalid bank account" }).required("bank account is required"),
        bank_mfo: Yup.string().matches(regex.bank_mfo, { message: "Invalid bank mfo" }).required("bank mfo is required"),
        supervisor: Yup.string().matches(regex.full_name, { message: "Invalid supervisor" }).required("supervisor is required"),
        chief_accountant: Yup.string().matches(regex.full_name, { message: "Invalid chief accountant" }).required("chief accountant is required"),
    });

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (data?.bank_info) {
            setEmptyData({
                TIN: data.bank_info.TIN,
                qqs_register_code: data.bank_info.qqs_register_code,
                bank_account: data.bank_info.bank_account,
                bank_mfo: data.bank_info.bank_mfo,
                supervisor: data.bank_info.supervisor,
                chief_accountant: data.bank_info.chief_accountant,
            });
        }
    }, []);

    const onUpdate = async (values, setSubmitting) => {
        const requestBody = { ...values, id: data.id };
        setIsWaiting(true);
        await axios
            .put(`${REACT_APP.URL}/api/hrp/tts/adm/provider/bank-info`, requestBody, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then(async () => {
                toast.success("Success!", { autoClose: 2000 });
                handleClose();
                await getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred");
            });
        setIsWaiting(false);
        setSubmitting(false);
    };

    return (
        <div className="addhotel">
            <Button onClick={handleOpen} variant="outlined">
                Set Bank Info
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            Enter provider bank info
                        </Typography>
                        <Formik initialValues={emptyData} validationSchema={validateCompany} onSubmit={onUpdate}>
                            {({ values, errors, isSubmitting }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={2} xs={12}>
                                            <Grid item xs={12} sm={6}>
                                                <Field fullWidth variant="standard" placeholder="123456789" name="TIN" component={TextField} label="TIN" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    fullWidth
                                                    variant="standard"
                                                    placeholder="123456789012"
                                                    name="qqs_register_code"
                                                    component={TextField}
                                                    label="Qqs register code"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    fullWidth
                                                    variant="standard"
                                                    placeholder="12345678901234567890"
                                                    name="bank_account"
                                                    component={TextField}
                                                    label="Bank account"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    fullWidth
                                                    variant="standard"
                                                    placeholder="12345"
                                                    name="bank_mfo"
                                                    component={TextField}
                                                    label="Bank mfo"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    fullWidth
                                                    variant="standard"
                                                    placeholder="full name"
                                                    name="supervisor"
                                                    component={TextField}
                                                    label="Supervisor"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    fullWidth
                                                    variant="standard"
                                                    placeholder="full name"
                                                    name="chief_accountant"
                                                    component={TextField}
                                                    label="Chief accountant"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <div className="left_right">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                            Close
                                        </Button>
                                        {isSubmitting ? (
                                            <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Submit
                                            </LoadingButton>
                                        ) : (
                                            <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
