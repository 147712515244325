/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "./styles.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function EnhancedTableHead() {
    const headCells = [
        { id: "id", numeric: false, label: "Providers", align: "left" },
        { id: "status", numeric: true, label: "", align: "right" },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align}>
                        <div style={{ marginLeft: "14px" }}>{headCell.label}</div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    rowCount: PropTypes.number.isRequired,
};

export default function ProviderTable({ data }) {
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 250, px: 2 }} aria-labelledby="tableTitle" size="medium">
                        <EnhancedTableHead rowCount={data.length} />
                        <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <TableRow hover tabIndex={-1} key={row.id}>
                                    <TableCell colSpan={2} align="left" style={{ padding: "6px" }}>
                                        <div className={styles.tableItem}>
                                            <div className={styles.itemHead}>
                                                <div className={styles.itemStatus} style={{ backgroundColor: statusToColor(row.status) }} />
                                                <div className={styles.itemTitle}>
                                                    <div className={styles.itemName}>{row.name}</div>
                                                    <div className={styles.itemEmail}>{row.email}</div>
                                                </div>
                                            </div>
                                            <div className={styles.itemDetails} onClick={() => navigate(`/provider/${row.id}`)}>
                                                <Button variant="outlined">details</Button>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

const statusToColor = (status) => {
    switch (status) {
        case 1:
            // new
            return "#309FFF99";
        case 2:
            // active
            return "#22BB2299";
        case 3:
            // services are disabled
            return "#FFA50099";
        case 4:
            // deactive
            return "#FF000099";

        default:
            return "#2F4F4F99";
    }
};
