import React from "react";
import Box from "@mui/material/Box";
import HotelCategoryTabs from "../components/Hotel/HotelCategories/Tabs";
import HotelCategoryAmenity from "../components/Hotel/HotelCategories/Amenities/Index";

export default function HotelCategoryAmenityPage() {
    return (
        <Box sx={{ width: "100%" }}>
            <HotelCategoryTabs />
            <HotelCategoryAmenity />
        </Box>
    );
}
