/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import REACT_APP from "../../environment";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function ChangeFile({ id, handleMenuClose }) {
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onUpdate = async () => {
        setLoading(true);
        const data = new FormData();
        data.append("id", id);
        data.append("file", file);
        await axios
            .patch(`${REACT_APP.URL}/api/hrp/tts/adm/library-file`, data, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then(async (res) => {
                setLoading(false);
                toast.success("Success!", { autoClose: 2000 });
                handleClose();
                handleMenuClose();
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error("An error occurred");
            });
    };

    return (
        <div className="addhotel">
            <Button onClick={handleOpen}>Change file</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            Change file
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} className="marginBottom">
                                <Button type="submit" variant="contained" disabled={loading}>
                                    Choose File
                                    <input
                                        disabled={loading}
                                        id="hide-file-input"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={(e) => setFile(e.target.files[0])}
                                    />
                                </Button>
                                <span>&nbsp; &nbsp;{file.name}</span>
                            </Grid>
                        </Grid>
                        <div className="left_right">
                            <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                Close
                            </Button>
                            {loading ? (
                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                    Update
                                </LoadingButton>
                            ) : (
                                <Button onClick={onUpdate} type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                    Update
                                </Button>
                            )}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
