import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import axios from "axios";
import ENV from "../../environment";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import "./Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { getBookingFilter, setSBookingType, setSCategory, setSHotel, setSRoom } from "../../store/booking-table-filer";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const BigCalendar = () => {
    const dispatch = useDispatch();
    const hotelFiler = useSelector(getBookingFilter);
    const [events, setEvents] = useState([]);
    const [hotels, setHotels] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState(hotelFiler.shotel);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(hotelFiler.scategory);
    const [rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(hotelFiler.sroom);
    const [bookingTypes, setBookingTypes] = useState([]);
    const [selectedBookingType, setSelectedBookingType] = useState(hotelFiler.sbookingtype);

    const getBooking = async () => {
        const cn = (el) =>
            (el.type === 1 && "green") ||
            (el.type === 2 && "purple") ||
            (el.type === 3 && "blue") ||
            (el.type === 4 && "yellow") ||
            (el.type === 5 && "orange");

        const searchParams = selectedCategory || selectedBookingType ? new URLSearchParams() : "";
        if (selectedCategory) searchParams.append("category", selectedCategory);
        if (selectedCategory && selectedRoom) searchParams.append("room", selectedRoom);
        if (selectedBookingType) searchParams.append("type", selectedBookingType);

        await axios(`${ENV.URL}/api/hdp/tts/adm/hotel/booking/${selectedHotel}?${searchParams.toString()}`, {
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => {
                res.data.data?.forEach((el) => {
                    el.start = new Date(el.start_date);
                    el.end = new Date(el.end_date);
                    el.title = (
                        <p id="c_title" className={`${cn(el)}`}>
                            {el.room_number}-xona
                            <span id="f_name" className={`${cn(el)}`}>
                                {el.first_name?.slice(0, 1) + "." + el.last_name}
                            </span>
                        </p>
                    );
                });
                setEvents(res.data.data);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
            });
    };

    const getHotels = async () => {
        await axios(`${ENV.URL}/api/hdp/tts/adm/hotel`, {
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => setHotels(res.data.data.hotels))
            .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
    };

    const getRooms = async () => {
        await axios(`${ENV.URL}/api/hdp/tts/adm/hotel/room/${selectedHotel}`, {
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => setRooms(res.data.data))
            .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
    };

    const getBookingTypes = async () => {
        await axios(`${ENV.URL}/api/hdp/tts/adm/hotel/booking/types`, {
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => setBookingTypes(res.data.data))
            .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
    };

    const getCategories = async () => {
        await axios
            .get(`${ENV.URL}/api/hdp/tts/adm/hotel/category/${selectedHotel}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => setCategories(res.data.data))
            .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
    };

    const changeSelectHotel = async (e) => {
        setSelectedHotel(e.target.value);
        setSelectedCategory(0);
        setSelectedRoom(0);
        dispatch(setSHotel(e.target.value));
    };

    useEffect(() => {
        if (selectedHotel) getCategories();
    }, [selectedHotel]);

    const changeSelectCategory = async (e) => {
        setSelectedCategory(e.target.value);
        setSelectedRoom(0);
        dispatch(setSCategory(e.target.value));
    };

    useEffect(() => {
        if (selectedCategory) getRooms();
    }, [selectedCategory]);

    const changeSelectRoom = async (e) => {
        setSelectedRoom(e.target.value);
        dispatch(setSRoom(e.target.value));
    };

    const changeSelectBookingType = async (e) => {
        setSelectedBookingType(e.target.value);
        dispatch(setSBookingType(e.target.value));
    };

    useEffect(() => {
        getHotels();
        getBookingTypes();
    }, []);

    useEffect(() => {
        if (selectedHotel) getBooking();
    }, [selectedBookingType, selectedCategory, selectedHotel, selectedRoom]);

    return (
        <div id="big_cal" className="container py-5">
            <div className="my-calendar">
                <Box sx={{ minWidth: 120, marginBottom: 2 }} className="row g-5 mb-4">
                    <div className="col-md-2">
                        <FormControl fullWidth size="small">
                            <InputLabel>{"Hotel"}</InputLabel>
                            <Select value={selectedHotel} label="Hotel" onChange={changeSelectHotel}>
                                {hotels.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-2">
                        <FormControl fullWidth size="small">
                            <InputLabel>{"Categories"}</InputLabel>
                            <Select value={selectedCategory} disabled={selectedHotel === ""} label="Categories" onChange={changeSelectCategory}>
                                <MenuItem value={0}>all</MenuItem>
                                {categories.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-2">
                        <FormControl fullWidth size="small">
                            <InputLabel>{"Room"}</InputLabel>
                            <Select value={selectedRoom} disabled={selectedCategory === 0} label="Room" onChange={changeSelectRoom}>
                                <MenuItem value={0}>all</MenuItem>
                                {rooms.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.number} - xona
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-2">
                        <FormControl fullWidth size="small">
                            <InputLabel>{"Booking type"}</InputLabel>
                            <Select value={selectedBookingType} label="Booking type" onChange={changeSelectBookingType}>
                                <MenuItem value={0}>all</MenuItem>
                                {bookingTypes.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Box>
                {selectedHotel ? (
                    <DnDCalendar
                        localizer={localizer}
                        events={events}
                        tooltipAccessor={"tooltip"}
                        startAccessor="start"
                        endAccessor="end"
                        longPressThreshold={10}
                        eventPropGetter={() => ({ style: { borderLeft: "3px solid red", borderRadius: "0" } })}
                    />
                ) : (
                    <Alert variant="outlined" severity="info">
                        Choose hotel
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default BigCalendar;
