import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import { updateWorkSpace } from "../context/WorkSpace";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

export default function CustomizedMenus() {
    const [workSpace] = React.useContext(updateWorkSpace);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
            >
                <MenuIcon className="mui_icon" />
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {workSpace === "hrp" && (
                    <React.Fragment>
                        <NavLink to="/home" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Home</MenuItem>
                        </NavLink>
                        <NavLink to="/company" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Company</MenuItem>
                        </NavLink>
                        <NavLink to="/policy" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Policy</MenuItem>
                        </NavLink>
                        <NavLink to="/university" onClick={handleClose} className="nav_mobile">
                            <MenuItem>University</MenuItem>
                        </NavLink>
                        <NavLink to="/provider" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Provider</MenuItem>
                        </NavLink>
                    </React.Fragment>
                )}
                {workSpace === "hdp" && (
                    <React.Fragment>
                        <NavLink to="/home" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Home</MenuItem>
                        </NavLink>
                        <NavLink to="/hotel" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Hotel</MenuItem>
                        </NavLink>
                        <NavLink to="/booking" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Booking</MenuItem>
                        </NavLink>
                        <NavLink to="/reports" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Reports</MenuItem>
                        </NavLink>
                        <NavLink to="/amenities" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Amenities</MenuItem>
                        </NavLink>
                        <NavLink to="/booking-type" onClick={handleClose} className="nav_mobile">
                            <MenuItem>Booking type</MenuItem>
                        </NavLink>
                    </React.Fragment>
                )}
            </StyledMenu>
        </div>
    );
}
