import React from "react";
import Provider from "../components/Provider/index";

const ProviderPage = () => {
    return (
        <>
            <Provider />
        </>
    );
};

export default ProviderPage;
