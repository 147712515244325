/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Spinner from "react-spinner-material";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import axios from "axios";
import REACT_APP from "../../environment.js";
import ProviderTable from "./Table.jsx";
import AddProvider from "./Create.jsx";

const Provider = () => {
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [isWaiting, setIsWaiting] = useState(false);
    const [data, setData] = React.useState([]);

    const getData = async () => {
        setLoading(true);
        await axios
            .get(`${REACT_APP.URL}/api/hrp/tts/adm/provider`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
    };
    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div style={{ maxWidth: "80rem", marginInline: "auto", padding: "20px" }}>
            {isWaiting && <div className="ca-overlay"></div>}
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%" }}>
                    {!error && data.length === 0 && (
                        <Alert variant="outlined" severity="warning">
                            There are no providers yet
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            An error occurred
                        </Alert>
                    )}
                    {data.length > 0 && <ProviderTable data={data} getProviders={getData} />}
                </Box>
            )}
            <AddProvider getProviders={getData} />
        </div>
    );
};

export default Provider;
