import React from "react";
import Booking from "../components/Reports/Tabs";

const ReportsPage = () => {
    return (
        <>
            <Booking />
        </>
    );
};

export default ReportsPage;
