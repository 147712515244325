/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import REACT_APP from "../../environment";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import { Message } from "./Context";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function DeleteCityModal({ id, handleMenuClose, getAllHotels }) {
    const [, setMessage] = useContext(Message);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onDelete = async () => {
        setMessage(true);
        setLoading(true);
        try {
            await axios.delete(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/booking-type/${id}`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
                data: { id: id },
            });
            setLoading(false);
            setMessage(false);
            toast.success("Success!", { autoClose: 2000 });
            handleClose();
            handleMenuClose();
        } catch (err) {
            setLoading(false);
            console.error(err);
            toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
        }
    };

    const keyUp = (e) => {
        console.log(e.keyCode);
    };

    useEffect(() => {
        document.addEventListener("keyup", keyUp);
        return () => {
            document.removeEventListener("keyup", keyUp);
        };
    }, []);

    return (
        <div>
            <Button onClick={handleOpen}>Delete</Button>
            <Modal
                aria-labelledby="transition-modal-title-2"
                aria-describedby="transition-modal-description-2"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title-2" variant="h6" component="h2" className="marginBottom">
                            Are you sure you want to delete it?
                        </Typography>
                        <div className="left_right">
                            <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                Close
                            </Button>
                            {loading ? (
                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                    Delete
                                </LoadingButton>
                            ) : (
                                <Button onClick={onDelete} variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                    Delete
                                </Button>
                            )}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
