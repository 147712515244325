import React, { useEffect, useState } from "react";
import Table from "./Table";
import AddHotel from "./AddAmenity";
import "./style.css";
import MessageProvider from "./Context";
import REACT_APP from "../../../environment";
import axios from "axios";

const Hotel = () => {
    const [isWaiting, setIsWaiting] = useState(false);
    const [amenityGroups, setAmenityGroups] = useState([]);

    const getAmenityGroups = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/amenity/group`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((res) => setAmenityGroups(res.data.data))
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAmenityGroups();
    }, []);

    return (
        <div className="hotel">
            {isWaiting && <div className="ca-overlay"></div>}
            <MessageProvider>
                <Table setIsWaiting={setIsWaiting} amenityGroups={amenityGroups} />
                <AddHotel amenityGroups={amenityGroups} />
            </MessageProvider>
        </div>
    );
};

export default Hotel;
