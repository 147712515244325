import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CompanySpinnerFunction(props) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <div style={{ width: "100%", textAlign: "center" }}>
                <CircularProgress variant="determinate" value={props.value} />
            </div>
            <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

CompanySpinnerFunction.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

export default function CompanySpinner({ value }) {
    return <CompanySpinnerFunction value={value} />;
}
