import React from "react";
import Company from "../components/Company";

const CompanyPage = () => {
    return (
        <>
            <Company />
        </>
    );
};

export default CompanyPage;
