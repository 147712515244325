import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: { shotel: "", scategory: 0, sroom: 0, sbookingtype: 0 } };

export const bookingFilerSlice = createSlice({
    name: "result",
    initialState,
    reducers: {
        setSHotel: (state, action) => {
            state.value.shotel = action.payload;
            state.value.scategory = 0;
            state.value.sroom = 0;
        },
        setSCategory: (state, action) => {
            state.value.scategory = action.payload;
            state.value.sroom = 0;
        },
        setSRoom: (state, action) => {
            state.value.sroom = action.payload;
        },
        setSBookingType: (state, action) => {
            state.value.sbookingtype = action.payload;
        },
    },
});

export const { setSHotel, setSCategory, setSRoom, setSBookingType } = bookingFilerSlice.actions;

export const getBookingFilter = (state) => {
    return state.bookingfilter.value;
};

export default bookingFilerSlice.reducer;
