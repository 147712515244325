/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import Spinner from "react-spinner-material";
import REACT_APP from "../../../environment";
import axios from "axios";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function SetProviderAddress({ data, getData, setIsWaiting }) {
    const [emptyData, setEmptyData] = React.useState({});
    const validateCompany = Yup.object({
        country: Yup.string().matches({ message: "Invalid country" }).required("Country is required"),
        region: Yup.string().matches(regex.region, { message: "Invalid region" }).required("Region is required"),
        city: Yup.string().matches(regex.city, { message: "Invalid city" }).required("City is required"),
        address1: Yup.string().matches(regex.address1, { message: "invalid address" }).required("Address1 is required"),
        address2: Yup.string().matches(regex.address2, { message: "invalid address" }).nullable(),
        zip_code: Yup.string().matches(regex.zip_code, { message: "invalid zip code" }).nullable(),
    });

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [country, setcountry] = useState([]);
    const [regions, setregions] = useState([]);

    const getCountry = async () => {
        await axios(`${REACT_APP.COMMON_API}/tts/api/v01/countries`, {
            headers: {
                "client-id": REACT_APP.COMMON_ID,
                secret: REACT_APP.COMMON_SECRET,
            },
        })
            .then((res) => setcountry(res.data.data))
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred!");
            });
    };
    const getRegions = async () => {
        await axios(`${REACT_APP.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
            headers: {
                "client-id": REACT_APP.COMMON_ID,
                secret: REACT_APP.COMMON_SECRET,
            },
        })
            .then((res) => setregions(res.data.data))
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred!");
            });
    };

    useEffect(() => {
        if (data?.address) {
            setEmptyData({
                country: data.address.country,
                region: data.address.region,
                city: data.address.city,
                address1: data.address.address1,
                address2: data.address.address2 || undefined,
                zip_code: data.address.zip_code || undefined,
            });
        }
        (async () => {
            await getCountry();
            await getRegions();
        })();
    }, []);

    const onUpdate = async (values, setSubmitting) => {
        const requestBody = { ...values, id: data.id };
        setIsWaiting(true);
        await axios
            .put(`${REACT_APP.URL}/api/hrp/tts/adm/provider/address`, requestBody, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then(async () => {
                toast.success("Success!", { autoClose: 2000 });
                handleClose();
                await getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred");
            });
        setIsWaiting(false);
        setSubmitting(false);
    };

    return (
        <div className="addhotel">
            <Button onClick={handleOpen} variant="outlined">
                Set Address
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            Enter provider address
                        </Typography>
                        <Formik initialValues={emptyData} validationSchema={validateCompany} onSubmit={onUpdate}>
                            {({ values, errors, isSubmitting }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={2} xs={12}>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    fullWidth
                                                    select
                                                    variant="standard"
                                                    component={TextField}
                                                    disableCloseOnSelect
                                                    onClick={() => (values.region = undefined)}
                                                    name="country"
                                                    label="Select Country"
                                                >
                                                    {country.map((el) => (
                                                        <MenuItem key={el.id} value={el.name}>
                                                            {el.native_name}
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {values.country === "Uzbekistan" ? (
                                                    <Field fullWidth select variant="standard" component={TextField} name="region" label="Select Region">
                                                        {regions.map((el) => (
                                                            <MenuItem key={el.id} value={el.name}>
                                                                {el.native_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                ) : (
                                                    <Field fullWidth variant="standard" name="region" component={TextField} label="Region" />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field fullWidth variant="standard" name="city" component={TextField} label="City / Village" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field fullWidth variant="standard" name="address1" component={TextField} label="Address 1" />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field fullWidth variant="standard" name="address2" component={TextField} label="Address 2" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field fullWidth variant="standard" name="zip_code" component={TextField} label="Zipcode" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <div className="left_right">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                            Close
                                        </Button>
                                        {isSubmitting ? (
                                            <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Submit
                                            </LoadingButton>
                                        ) : (
                                            <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
