/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import REACT_APP from "../../environment";
import FileSaver from "file-saver";
import Spinner from "react-spinner-material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function ViewFile({ id, name, handleMenuClose }) {
    const [open, setOpen] = React.useState(false);
    const [src, setSrc] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        handleMenuClose();
    };

    function getLogo() {
        fetch(`${REACT_APP.URL}/api/hrp/tts/adm/library-file/${id}`, {
            method: "GET",
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
        })
            .then((res) => res.blob())
            .then((blob) => {
                setFile(blob);
                setSrc(window.URL.createObjectURL(blob));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getLogo();
    }, []);

    function download() {
        FileSaver.saveAs(file, `${name}_${new Date().getTime()}`);
    }

    return (
        <div className="addhotel">
            <Button onClick={handleOpen}>View File</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal" style={{ height: "fit-content", width: "800px" }}>
                        {!src ? (
                            <div style={{ margin: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                            </div>
                        ) : (
                            <>
                                <iframe src={src} title="library" frameborder="0" style={{ width: "100%", height: "600px" }} />
                                <div className="left_right">
                                    <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                        Close
                                    </Button>
                                    <Button onClick={download} type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                        Download
                                    </Button>
                                </div>
                            </>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
