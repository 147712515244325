import React from "react";
// import {Regions} from "../components/common";
import Regions from "../components/common/regions";

const RegionsPage = () => {
    return (
        <>
            <Regions />
        </>
    );
};

export default RegionsPage;
