import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";

export default function DeleteLibrary({ id, setIsWaiting, getAllFiles, handleMenuClose }) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const generatedEmail = async () => {
        handleClose();
        // setIsWaiting(true);
        await axios
            .delete(`${REACT_APP.URL}/api/hrp/tts/adm/library-file`, {
                data: { id },
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then(() => {
                // setIsWaiting(false);
                toast.success("Success!", { autoClose: 2000 });
                handleMenuClose();
                getAllFiles();
            })
            .catch((err) => {
                // setIsWaiting(false);
                handleMenuClose();
                console.log(err);
                toast.error("Something went wrong");
            });
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>Delete</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Do you want to delete the library file?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={generatedEmail}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
