import React from "react";
import ProviderDetails from "../components/Provider/Deatils/Index";

const ProviderDetailsPage = () => {
    return (
        <>
            <ProviderDetails />
        </>
    );
};

export default ProviderDetailsPage;
