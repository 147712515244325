import React from "react";
import Aminities from "../components/Aminities/Tabs";

const HotelPage = () => {
    return (
        <>
            <Aminities />
        </>
    );
};

export default HotelPage;
