import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import axios from "axios";
import ENV from "../../../environment";
import Spinner from "react-spinner-material";
import regex from "tts-hrp-val-lib";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const HotelInfo = () => {
  const { hotelid } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getData = async () => {
    await axios
      .get(`${ENV.URL}/api/hdp/tts/adm/hotel/info/${hotelid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token"),
          "client-id": ENV.CLIENTID,
          "client-secret": ENV.SECRET,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setLoadingData(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoadingData(false);
        toast.error(
          err?.response?.data?.errors[0]?.message || "An error occurred"
        );
      });
  };

  const validate = Yup.object({
    info: Yup.string().required("Field is required").matches(regex.text, { message: "Enter valid name" }),
  });
  const emptyData = {
    info: data?.info ? data.info : "",
  };

  const onSubmit = async (values) => {
    const body = {
      info: values?.info,
    };
    setLoading(true);
    await axios
      .put(`${ENV.URL}/api/hdp/tts/adm/hotel/info/${hotelid}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token"),
          "client-id": ENV.CLIENTID,
          "client-secret": ENV.SECRET,
        },
      })
      .then((res) => {
        setLoading(false);
        getData();
        toast.success("Saved successfully", { autoClose: 2000 });
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.errors[0]?.message || "An error occurred"
        );
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loadingData ? (
                   <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                   <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
               </div>
      ) : (
        <div className="card container p-0 mt-3">
          <div className="card-header">{data?.name}</div>
          <div className="card-body">
            <h5 className="card-title text-primary">{"About hotel"}</h5>
            <p className="card-text">{data?.info}</p>
            <Button
              className="float-end"
              variant="contained"
              color="primary"
              onClick={handleOpen}
            >
              {data?.info?.length ? "Update" : "Add info"}
            </Button>
          </div>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="modal_res">
            <>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                className="marginBottom"
              >
                {"Enter hotel info"}
              </Typography>
              <Formik
                initialValues={emptyData}
                validationSchema={validate}
                onSubmit={async (values) => {
                  onSubmit(values);
                  return new Promise((res) => setTimeout(res, 500));
                }}
              >
                {({ values, errors }) => (
                  <Form autoComplete="off" className="mt-4">
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Field
                          defaultValue={data?.info}
                          fullWidth
                          name="info"
                          size="small"
                          component={TextField}
                          label={"About hotel"}
                          type="text"
                          multiline
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                    <div className="right_left">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="error"
                      >
                        {"Close"}
                      </Button>
                      <>
                        {loading ? (
                          <LoadingButton
                            loading
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            {"Submit"}
                          </LoadingButton>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            {"Submit"}
                          </Button>
                        )}
                      </>
                    </div>
                      {loading && <div className="overlay" />}
                  </Form>
                )}
              </Formik>
            </>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default HotelInfo;
