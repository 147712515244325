import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import ENV from "../../environment";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportFilter, setRHotel } from "../../store/report-filer";
import Spinner from "react-spinner-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const BigCalendar = () => {
    const dispatch = useDispatch();
    const reportFilter = useSelector(getReportFilter);
    const [hotels, setHotels] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState(reportFilter.rhotel);
    const [monthLoading, setMonthLoading] = useState(false);
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const getHotels = async () => {
        await axios(`${ENV.URL}/api/hdp/tts/adm/hotel`, {
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => setHotels(res.data.data.hotels))
            .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
    };

    const getMonth = async () => {
        setMonthLoading(true);
        await axios
            .get(`${ENV.URL}/api/hdp/tts/adm/hotel/${selectedHotel}/check/room`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => setMonths(res.data.data))
            .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
        setMonthLoading(false);
    };

    useEffect(() => {
        if (selectedHotel) getMonth();
    }, [selectedHotel]);

    const changeSelectHotel = async (e) => {
        setSelectedHotel(e.target.value);
        setSelectedMonth(null);
        setMonths([]);
        dispatch(setRHotel(e.target.value));
    };

    const changeSelectDate = async (e) => {
        setSelectedMonth(e.target.value);
    };

    useEffect(() => {
        getHotels();
    }, []);

    const [file, setFile] = useState(null);
    const [src, setSrc] = useState(null);
    const [downloadReport, setDownloadReport] = useState(false);

    function getReportFile() {
        setDownloadReport(true);
        fetch(`${ENV.URL}/api/hdp/tts/adm/hotel/${selectedHotel}/check/room/${selectedMonth}`, {
            method: "GET",
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => res.blob())
            .then((blob) => {
                setFile(blob);
                setSrc(window.URL.createObjectURL(blob));
                setDownloadReport(false);
            })
            .catch((err) => {
                console.log(err);
                setDownloadReport(false);
            });
    }

    const defaultDate = dayjs(new Date().toISOString().slice(0, 10));
    const [startDate, setStartDate] = useState(dayjs(new Date().toISOString().slice(0, 8) + "01"));
    const [endDate, setEndDate] = useState(defaultDate);
    const onChangeStartDate = (e) => setStartDate(e);
    const onChangeEndDate = (e) => setEndDate(e);

    function getReportFile2() {
        setDownloadReport(true);
        const searchParams = new URLSearchParams();
        searchParams.append("start_date", startDate.tz("Asia/Tashkent").format("YYYY-MM-DD"));
        searchParams.append("end_date", endDate.tz("Asia/Tashkent").format("YYYY-MM-DD"));
        searchParams.append("name", startDate.tz("Asia/Tashkent").format("DD.MM.YYYY") + " - " + endDate.tz("Asia/Tashkent").format("DD.MM.YYYY"));

        fetch(`${ENV.URL}/api/hdp/tts/adm/hotel/${selectedHotel}/check/room/generate?${searchParams.toString()}`, {
            method: "GET",
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => res.blob())
            .then((blob) => {
                setFile(blob);
                setSrc(window.URL.createObjectURL(blob));
                setDownloadReport(false);
            })
            .catch((err) => {
                console.log(err);
                setDownloadReport(false);
            });
    }

    return (
        <div id="big_cal" className="container py-5">
            <div className="my-calendar">
                <Box sx={{ minWidth: 120, marginBottom: 2 }} className="row g-5 mb-4">
                    <div className="col-md-2">
                        <FormControl fullWidth size="small">
                            <InputLabel>{"Hotel"}</InputLabel>
                            <Select value={selectedHotel} label="Hotel" onChange={changeSelectHotel}>
                                {hotels.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-2">
                        <FormControl fullWidth size="small">
                            <InputLabel>{"Report Date"}</InputLabel>
                            <Select label="Report Date" value={selectedMonth} disabled={!selectedHotel || monthLoading} onChange={changeSelectDate}>
                                {months.map((value, index) => (
                                    <MenuItem key={index} value={value.month}>
                                        {value.month === 1 ? "January" : null}
                                        {value.month === 2 ? "February" : null}
                                        {value.month === 3 ? "March" : null}
                                        {value.month === 4 ? "April" : null}
                                        {value.month === 5 ? "May" : null}
                                        {value.month === 6 ? "June" : null}
                                        {value.month === 7 ? "July" : null}
                                        {value.month === 8 ? "August" : null}
                                        {value.month === 9 ? "September" : null}
                                        {value.month === 10 ? "October" : null}
                                        {value.month === 11 ? "November" : null}
                                        {value.month === 12 ? "December" : null}
                                        {value.month === 23 ? "Current month" : null}
                                    </MenuItem>
                                ))}
                                <MenuItem value={"Custom"}>Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {selectedMonth === "Custom" ? (
                        <React.Fragment>
                            <div className="col-md-3 report-date">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]} fullWidth size="small">
                                        <DatePicker label="Start date" value={startDate} onChange={onChangeStartDate} maxDate={endDate} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className="col-md-3 report-date">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                        <DatePicker label="End date" value={endDate} minDate={startDate} onChange={onChangeEndDate} maxDate={defaultDate} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="col-md-6"></div>
                    )}

                    <div className="col-md-2">
                        {selectedMonth === "Custom" ? (
                            <Button variant="contained" color="primary" onClick={getReportFile2} disabled={!startDate || !endDate || downloadReport}>
                                view report
                            </Button>
                        ) : (
                            <Button variant="contained" color="primary" onClick={getReportFile} disabled={!selectedMonth || downloadReport}>
                                view report
                            </Button>
                        )}
                    </div>
                </Box>
                {downloadReport ? (
                    <div style={{ margin: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                    </div>
                ) : (
                    src && <iframe src={src} title="Report" frameborder="0" style={{ width: "100%", height: "600px" }} />
                )}
            </div>
        </div>
    );
};

export default BigCalendar;
