import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import REACT_APP from "../../environment";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ university_id, provider_id, getData }) {
    // eslint-disable-next-line
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validate = Yup.object({
        provider_id: Yup.string().required("Provider is required"),
    });
    const emptyData = {
        provider_id: "",
    };

    const onSubmit = async (values) => {
        values.university_id = university_id;
        setLoading(true);
        await axios({
            method: provider_id ? "put" : "patch",
            url: `${REACT_APP.URL}/api/hrp/tts/adm/university/provider`,
            data: values,
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
        })
            .then((res) => {
                setLoading(false);
                toast.success("Attached successfully", { autoClose: 2000 });
                handleClose();
                getData();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err.message);
            });
    };

    const getProviders = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/tts/adm/university/providers`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then((res) => {
                setData(res.data.data);
            })
            .catch((err) => {
                toast.error("An error occurred");
                console.log(err.message);
            });
    };
    useEffect(() => {
        getProviders();
    }, []);

    return (
        <div className="addcompany">
            <Button onClick={handleOpen} variant="outlined">
                {provider_id ? "Update" : "Attach"} Provider
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            Attach Provider
                        </Typography>
                        <Formik
                            initialValues={emptyData}
                            validationSchema={validate}
                            onSubmit={async (values) => {
                                onSubmit(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth select name="provider_id" component={TextField} label="Select Provider">
                                            {data.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.id} key={index}>
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Field>
                                    </Grid>
                                    <div className="left_right">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error" className="mt-5">
                                            Cancel
                                        </Button>
                                        <>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success" className="mt-5">
                                                    Save
                                                </LoadingButton>
                                            ) : (
                                                <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    Save
                                                </Button>
                                            )}
                                        </>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
