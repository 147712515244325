import React, { useState } from "react";
import Table from "./Table";
import AddHotel from "./AddHotel";
import "./style.css";
import MessageProvider from "./Context";

const Hotel = () => {
    const [isWaiting, setIsWaiting] = useState(false);
    return (
        <div className="container py-5">
            {isWaiting && <div className="ca-overlay"></div>}
            <MessageProvider>
                <AddHotel />
                <Table setIsWaiting={setIsWaiting} />
            </MessageProvider>
        </div>
    );
};

export default Hotel;
