import React from "react";
import Settings from "../components/Settings/Index-2";

const SettingsPage = () => {
    return (
        <>
            <Settings />
        </>
    );
};

export default SettingsPage;
