import React from "react";
import UniversityTable from "./UniversityTable";
import "./style.css";

const University = () => {
    return (
        <div className="university">
            <UniversityTable />
        </div>
    );
};

export default University;
