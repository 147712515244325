import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Alert } from "@mui/material";
import GenerateEmail from "./GenerateEmail";
import SetProviderAddress from "./Address";
import SetProviderBankInfo from "./BankInfo";

const ProviderAlert = ({ data, getData, setIsWaiting }) => {
    const [alert, setAlert] = useState({});

    useEffect(() => {
        if (data.status === 1) {
            setAlert({
                Component: <GenerateEmail data={data} getData={getData} setIsWaiting={setIsWaiting} />,
                message: data.name + " is new provider. To activate it, create a new user and send an activation message to the email about it",
            });
        } else if (!data.bank_info) {
            setAlert({
                Component: <SetProviderBankInfo data={data} getData={getData} setIsWaiting={setIsWaiting} />,
                message: "Provider's bank informations were not found, please fill them in. Lack of bank info can cause serious problems",
            });
        } else if (!data.address) {
            setAlert({
                Component: <SetProviderAddress data={data} getData={getData} setIsWaiting={setIsWaiting} />,
                message: "Provider's address informations were not found, please fill them in",
            });
        }
    }, [data]);

    return (
        <div>
            {alert.message && (
                <Alert severity="info" style={{ display: "flex", alignItems: "center" }}>
                    <div className={styles.alert}>
                        <div className={styles.alertMessage}>{alert.message}</div>
                        <div className={styles.alertButton}>{alert.Component}</div>
                    </div>
                </Alert>
            )}
        </div>
    );
};

export default ProviderAlert;
