/* eslint-disable no-unused-vars */
import React from "react";
import WorkSpace from "./WorkSpace";

const Library = () => {
    return (
        <div style={{ padding: "20px" }}>
            <WorkSpace />
        </div>
    );
};

export default Library;
