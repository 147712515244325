import React from "react";
import Box from "@mui/material/Box";
import HotelTabs from "../components/Hotel/Tabs";
import HotelInfo from "../components/Hotel/Hotel-info/index";

export default function HotelInfoPage() {
    return (
        <Box sx={{ width: "100%" }}>
            <HotelTabs />
            <HotelInfo />
        </Box>
    );
}
