import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";

export default function GenerateEmail({ id, setIsWaiting, getAllHotels, handleMenuClose }) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const generatedEmail = async () => {
        handleClose();
        setIsWaiting(true);
        await axios
            .patch(
                `${REACT_APP.URL}/api/hdp/tts/adm/hotel/generate-email`,
                { id },
                { headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET } }
            )
            .then(() => {
                setIsWaiting(false);
                toast.success("Success", { autoClose: 2000 });
                handleMenuClose();
                getAllHotels();
            })
            .catch((err) => {
                setIsWaiting(false);
                handleMenuClose();
                console.log(err);
                toast.error(err?.response?.data?.errors[0]?.message || "Something went wrong");
            });
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>Generate email</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Generate email</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Send contract message to email</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={generatedEmail}>Send</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
