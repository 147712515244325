import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import REACT_APP from "../../../environment";
import { toast } from "react-toastify";

export default function ProviderReGenerateEmail({ data, getData, setIsWaiting }) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const generatedEmail = async () => {
        // overlay
        handleClose();
        setIsWaiting(true);
        await axios
            .patch(
                `${REACT_APP.URL}/api/hrp/tts/adm/provider/regenerate-email`,
                { id: data.id },
                { headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET } }
            )
            .then(() => {
                toast.success("Success!", { autoClose: 2000 });
                getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong");
            });
        setIsWaiting(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen} variant="outlined">
                Regenerate email
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Regenerate email</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to regenerate email?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={generatedEmail}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
