import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import REACT_APP from "../../environment.js";
import Spinner from "react-spinner-material";
import Alert from "@mui/material/Alert";
import { Message } from "./Context.js";
import Action from "./Action.js";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { getHotelFilter, setHFOrderBy, setHFPage, setHFPartner, setHFReset, setHFSearch, setHFSortBy, setHFStatus } from "../../store/hotel-table-filer.js";
import getNormalDate from "../../helpers/getNormaldate.js";

const headCells = [
    { id: "id", numeric: false, label: "Id", align: "left" },
    { id: "status", numeric: true, label: "Status", align: "left" },
    { id: "name", numeric: true, label: "Name", align: "left" },
    { id: "email", numeric: true, label: "Email", align: "center" },
    { id: "partner", numeric: true, label: "Partnership type", align: "center" },
    { id: "on booking", numeric: true, label: "On booking", align: "center" },
    { id: "address_region", numeric: true, label: "Region", align: "center" },
    { id: "license_exp", numeric: true, label: "License exp", align: "center" },
    { id: "created_date", numeric: true, label: "Created date", align: "center" },
    { id: "", numeric: true, label: "", align: "center" },
    {
        id: "action",
        numeric: true,
        label: "Actions",
        align: "center",
        renderCell: () => {
            return <></>;
        },
    },
];

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align}>
                        {headCell.id === "action" && <>&nbsp; &nbsp;&nbsp; &nbsp;</>}
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = { rowCount: PropTypes.number.isRequired };

export default function EnhancedTable({ setIsWaiting }) {
    const [message] = useContext(Message);
    const navigate = useNavigate();

    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const [data, setData] = React.useState([]);
    const [dataLength, setDataLength] = React.useState(0);

    const dispatch = useDispatch();
    const hotelFiler = useSelector(getHotelFilter);
    const rowsPerPage = 10;
    const [page, setPage] = React.useState(hotelFiler.page);
    const [fsearch, setfsearch] = useState(hotelFiler.fsearch);
    const [fstatus, setfstatus] = useState(hotelFiler.fstatus);
    const [fpartner, setfpartner] = useState(hotelFiler.fpartner);
    const [fsortby, setfsortby] = useState(hotelFiler.fsortby);
    const [forderby, setforderby] = useState(hotelFiler.forderby);

    const changeFSearch = (event) => {
        dispatch(setHFSearch(event.target.value));
        setfsearch(event.target.value);
    };
    const changeFStatus = (event) => {
        dispatch(setHFStatus(event.target.value));
        setfstatus(event.target.value);
    };
    const changeFType = (event) => {
        dispatch(setHFPartner(event.target.value));
        setfpartner(event.target.value);
    };
    const changeFSortBy = (event) => {
        dispatch(setHFSortBy(event.target.value));
        setfsortby(event.target.value);
    };
    const changeFOrderBy = (event) => {
        dispatch(setHFOrderBy(event.target.value));
        setforderby(event.target.value);
    };
    const resetFilter = () => {
        dispatch(setHFReset());
        setfsearch("");
        setfstatus(0);
        setfpartner(0);
        setfsortby("asc");
        setforderby("id");
        setPage(0);
        getHotels();
    };

    const getHotels = async () => {
        setLoading(true);
        const searchParams = new URLSearchParams();
        searchParams.append("page", page);
        searchParams.append("limit", rowsPerPage);
        searchParams.append("sortby", fsortby);
        searchParams.append("orderby", forderby);
        if (fstatus) searchParams.append("status", fstatus);
        if (fpartner) searchParams.append("partner", fpartner);
        if (document.getElementById("hotel-search").value) searchParams.append("search", document.getElementById("hotel-search").value);

        await axios
            .get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel?${searchParams.toString()}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((res) => {
                setData(res.data.data.hotels);
                setDataLength(res.data.data.length);
                setLoading(false);
                setError(false);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        dispatch(setHFPage(newPage));
        setPage(newPage);
    };

    React.useEffect(() => {
        getHotels();
    }, [message]);

    React.useEffect(() => {
        getHotels();
    }, [page]);

    const goToCategory = (id) => navigate(`/hotel/${id}/category`);

    return (
        <React.Fragment>
            <div style={{ display: "flex", alignItems: "baseline", flexWrap: "wrap", marginBottom: "10px" }}>
                <TextField className="pb-3" id="hotel-search" label="Search" variant="standard" value={fsearch} disabled={loading} onChange={changeFSearch} />
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} disabled={loading}>
                    <InputLabel id="partnership-type-label">partnership type</InputLabel>
                    <Select labelId="partnership-type-label" id="partnership-type-label" value={fpartner} onChange={changeFType} label="partnership type">
                        <MenuItem value={0}>all</MenuItem>
                        <MenuItem value={1}>member</MenuItem>
                        <MenuItem value={2}>partner</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} disabled={loading}>
                    <InputLabel id="status-label">status</InputLabel>
                    <Select labelId="status-label" id="status-label" value={fstatus} onChange={changeFStatus} label="status">
                        <MenuItem value={0}>all</MenuItem>
                        <MenuItem value={1}>new</MenuItem>
                        <MenuItem value={2}>in activation process</MenuItem>
                        <MenuItem value={3}>expired token</MenuItem>
                        <MenuItem value={4}>active</MenuItem>
                        <MenuItem value={5}>blocked</MenuItem>
                        <MenuItem value={6}>reqtomem</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} disabled={loading}>
                    <InputLabel id="order-by-label">order by</InputLabel>
                    <Select labelId="order-by-label" id="order-by-label" value={forderby} onChange={changeFOrderBy} label="order by">
                        <MenuItem value={"id"}>id</MenuItem>
                        <MenuItem value={"name"}>name</MenuItem>
                        <MenuItem value={"email"}>email</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} disabled={loading}>
                    <InputLabel id="sort-by-label">sort by</InputLabel>
                    <Select labelId="sort-by-label" id="sort-by-label" value={fsortby} onChange={changeFSortBy} label="sort by">
                        <MenuItem value={"asc"}>asc</MenuItem>
                        <MenuItem value={"desc"}>desc</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" className="mx-4" size="small" onClick={getHotels} disabled={loading}>
                    Filter
                </Button>
                <Button variant="outlined" size="small" onClick={resetFilter} disabled={loading}>
                    reset Filter
                </Button>
            </div>
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%" }}>
                    {!error && data.length === 0 && (
                        <Alert variant="outlined" severity="warning">
                            No hotel found
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            Could not get data
                        </Alert>
                    )}
                    {data.length >= 1 && (
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead rowCount={data.length} />
                                    <TableBody>
                                        {data.map((row) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={row.id}>
                                                    <TableCell align="left">{row.id}</TableCell>
                                                    <TableCell align="left">{row.hotel_status}</TableCell>
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell align="center">{row.email}</TableCell>
                                                    <TableCell align="center">{row.partner}</TableCell>
                                                    <TableCell align="center">{row.in_hbp ? "open" : "close"}</TableCell>
                                                    <TableCell align="center">{row.address_region}</TableCell>
                                                    <TableCell align="center">{getNormalDate(row.license_exp)}</TableCell>
                                                    <TableCell align="center">{getNormalDate(row.created_date)}</TableCell>
                                                    <TableCell align="left">
                                                        <Button
                                                            variant="outlined"
                                                            disabled={row.status !== 4 && row.status !== 6}
                                                            onClick={(e) => {
                                                                goToCategory(row.id);
                                                                sessionStorage.setItem("hotelname", row.name);
                                                            }}
                                                        >
                                                            manage
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Action
                                                            id={row.id}
                                                            status={row.status}
                                                            data={row}
                                                            name={row.name}
                                                            partner={row.partner}
                                                            in_hbp={row.in_hbp}
                                                            setIsWaiting={setIsWaiting}
                                                            getAllHotels={getHotels}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={dataLength}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </Paper>
                    )}
                </Box>
            )}
        </React.Fragment>
    );
}
