/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styles from "./styles.module.css";
import Checkbox from "@mui/material/Checkbox";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { FormControlLabel } from "@mui/material";
import ProviderGenerateEmail from "./GenerateEmail";
import ProviderAddress from "./Address";
import ProviderBankInfo from "./BankInfo";
import UpdateProvider from "./Update";
import ProviderReGenerateEmail from "./RegenerateEmail";
import ProviderActivated from "./StatusActive";
import ProviderServiceDisable from "./StatusDisService";
import ProviderDeactivated from "./StatusDeactive";

const ProviderSettings = ({ data, getData, setIsWaiting }) => {
    const [show, setShow] = useState(false);
    const checkbox = <Checkbox icon={<SettingsOutlinedIcon />} checkedIcon={<SettingsIcon />} onClick={() => setShow(!show)} />;

    return (
        <div className={styles.settings}>
            <FormControlLabel label={show ? "hide settings" : "show settings"} control={checkbox} />
            {show && (
                <div id="provider-settings">
                    <div className={styles.settingsItem}>
                        <div>
                            <div className={styles.settingsTitle}>Generate Email</div>
                            <div className={styles.settingsText}>
                                One time process for new providers only. Create user for provider and send activation message to email
                            </div>
                        </div>
                        <div>
                            <ProviderGenerateEmail data={data} getData={getData} setIsWaiting={setIsWaiting} />
                        </div>
                    </div>
                    <hr className={styles.line} />

                    {data.number_of_users === 1 && data.number_of_products === 0 && data.status === 2 && (
                        <React.Fragment>
                            <div className={styles.settingsItem}>
                                <div>
                                    <div className={styles.settingsTitle}>Regenerate Email</div>
                                    <div className={styles.settingsText}>
                                        Do when activation fails. Send a message to the email again and automatically change the user's password
                                    </div>
                                </div>
                                <div>
                                    <ProviderReGenerateEmail data={data} getData={getData} setIsWaiting={setIsWaiting} />
                                </div>
                            </div>
                            <hr className={styles.line} />
                        </React.Fragment>
                    )}

                    <div className={styles.settingsItem}>
                        <div>
                            <div className={styles.settingsTitle}>Address</div>
                            <div className={styles.settingsText}>Address information</div>
                        </div>
                        <div>
                            <ProviderAddress data={data} getData={getData} setIsWaiting={setIsWaiting} />
                        </div>
                    </div>
                    <hr className={styles.line} />

                    <div className={styles.settingsItem}>
                        <div>
                            <div className={styles.settingsTitle}>Bank account</div>
                            <div className={styles.settingsText}>Bank information</div>
                        </div>
                        <div>
                            <ProviderBankInfo data={data} getData={getData} setIsWaiting={setIsWaiting} />
                        </div>
                    </div>
                    <hr className={styles.line} />

                    <div className={styles.settingsItem}>
                        <div>
                            <div className={styles.settingsTitle}>update status</div>
                            <div className={styles.settingsText}>update provider status</div>
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <ProviderActivated data={data} getData={getData} setIsWaiting={setIsWaiting} />
                            <ProviderServiceDisable data={data} getData={getData} setIsWaiting={setIsWaiting} />
                            <ProviderDeactivated data={data} getData={getData} setIsWaiting={setIsWaiting} />
                        </div>
                    </div>
                    <hr className={styles.line} />

                    <div className={styles.settingsItem}>
                        <div>
                            <div className={styles.settingsTitle}>Update provider information</div>
                            <div className={styles.settingsText}>change basic information, email, website or name of provider</div>
                        </div>
                        <div>
                            <UpdateProvider data={data} getData={getData} setIsWaiting={setIsWaiting} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProviderSettings;
