/* eslint-disable no-unused-vars */
import { Button, MenuItem, Select } from "@mui/material";
import React, { useContext, useState } from "react";
import { updateWorkSpace } from "../../context/WorkSpace";
import { useNavigate } from "react-router-dom";

const WorkSpace = () => {
    const navigate = useNavigate();
    const [workSpace] = useContext(updateWorkSpace);
    const [newWorkSpace, setNewWorkSpace] = useState(workSpace);
    const handleChange = (e) => setNewWorkSpace(e.target.value);
    const confirm = () => {
        localStorage.setItem("work-space", newWorkSpace);
        navigate("/");
        window.location.reload();
        // window.location.href = "/";
    };

    return (
        <div>
            <h2 style={{ margin: "20px" }}>Work space</h2>
            <div style={{ display: "flex", gap: "20px", margin: "20px" }}>
                <Select value={newWorkSpace} onChange={handleChange} size="small" style={{ width: "200px" }}>
                    <MenuItem value="hrp">HR&Payroll</MenuItem>
                    <MenuItem value="hdp">Hotel Operational Day Portal</MenuItem>
                    <MenuItem value="common">Common UI</MenuItem>
                </Select>
                <Button type="button" variant="contained" color="success" onClick={confirm}>
                    confirm
                </Button>
            </div>
        </div>
    );
};

export default WorkSpace;
