import React from "react";
import Box from "@mui/material/Box";
import HotelTabs from "../components/Hotel/Tabs";
import HotelRoomsTable from "../components/Hotel/HotelRooms/RoomsTable";

export default function HotelRoomPage() {
    return (
        <Box sx={{ width: "100%" }}>
            <HotelTabs />
            <HotelRoomsTable />
        </Box>
    );
}
