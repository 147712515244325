import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Alert, Grid, MenuItem } from "@mui/material";
import axios from "axios";
import ENV from "../../../environment";
import { useParams } from "react-router-dom";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ getData }) {
    const [categories, setCategories] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { hotelid } = useParams();

    const validate = Yup.object({
        category: Yup.string().required("Field is required"),
        number: Yup.string().required("Field is required").matches(regex.text, { message: "Enter valid name" }),
    });
    const emptyData = {
        category: "",
        number: "",
    };
    const onSubmit = async (values) => {
        const body = {
            category: values?.category,
            number: values?.number.toString(),
        };
        setLoading(true);
        await axios
            .post(`${ENV.URL}/api/hdp/tts/adm/hotel/room/${hotelid}`, body, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => {
                setLoading(false);
                toast.success("Saved successfully", { autoClose: 2000 });
                handleClose();
                getData();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
            });
    };

    const getCategories = async () => {
        await axios
            .get(`${ENV.URL}/api/hdp/tts/adm/hotel/category/${hotelid}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => {
                setCategories(res.data.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
            });
    };
    useEffect(() => {
        getCategories();
    }, []);
    return (
        <div className="modal_res">
            <Button onClick={handleOpen} variant="contained" style={{ float: "right" }}>
                {"Add Room"}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="modal_res">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {"Enter room info"}
                        </Typography>
                        <Formik
                            initialValues={emptyData}
                            validationSchema={validate}
                            onSubmit={async (values) => {
                                onSubmit(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            {categories.length ? (
                                                <Field fullWidth select name="category" size="small" component={TextField} label={"Select Category"}>
                                                    {categories.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item.id} key={index}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Field>
                                            ) : (
                                                <Alert severity="info">You don' have any categories yet. Please add some categories and came here.</Alert>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field fullWidth name="number" size="small" component={TextField} label={"Room number"} type="number" />
                                        </Grid>
                                    </Grid>
                                    <div className="right_left">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                            {"Close"}
                                        </Button>
                                        <>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {"Submit"}
                                                </LoadingButton>
                                            ) : (
                                                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {"Submit"}
                                                </Button>
                                            )}
                                        </>
                                        {loading && <div className="overlay" />}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
