import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import ENV from "../../../environment";
import Spinner from "react-spinner-material";
import { useParams } from "react-router-dom";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ id, getTableData, close }) {
    const [categories, setCategories] = React.useState([]);
    const [data, setData] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [wait, setWait] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { hotelid } = useParams();

    const getCategories = async () => {
        await axios
            .get(`${ENV.URL}/api/hdp/tts/adm/hotel/category/${hotelid}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => setCategories(res.data.data))
            .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
    };

    const getData = async () => {
        setWait(true);
        await axios
            .get(`${ENV.URL}/api/hdp/tts/adm/hotel/room/${id}/${hotelid}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => {
                setData(res.data.data);
                setWait(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
                setWait(false);
            });
    };

    const validate = Yup.object({
        category: Yup.string().required("category is required"),
        number: Yup.string().required("number is required").matches(regex.text, { message: "Enter valid name" }),
    });

    var emptyData = data;

    const onUpdate = async (values) => {
        setLoading(true);
        if (values?.hotel_id && values?.status) {
            delete values.hotel_id;
            delete values.status;
        }
        const body = {
            id: values?.id,
            category: values?.category,
            number: values?.number.toString(),
        };
        await axios
            .put(`${ENV.URL}/api/hdp/tts/adm/hotel/room/${hotelid}`, body, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => {
                toast.success("Updated successfully", { autoClose: 2000 });
                handleClose();
                close();
                getTableData();
                getData();
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
            });
    };

    useEffect(() => {
        getCategories();
        getData();
    }, []);

    return (
        <div>
            {loading && <div className="overlay" />}
            <Typography onClick={handleOpen} title="Click">
                {"Update"}
            </Typography>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    {data === emptyData && (
                        <Box sx={style} className="modal_res">
                            <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                                {"Update room info"}
                            </Typography>
                            {wait ? (
                                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                                </div>
                            ) : (
                                <Formik
                                    initialValues={data}
                                    validationSchema={validate}
                                    onSubmit={async (values) => {
                                        onUpdate(values);
                                    }}
                                >
                                    {({ values, errors }) => (
                                        <Form autoComplete="off" className="mt-4">
                                            <>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12}>
                                                        <Field
                                                            fullWidth
                                                            select
                                                            name="category"
                                                            size="small"
                                                            defaultValue={data.category}
                                                            component={TextField}
                                                            label={"Select Category"}
                                                        >
                                                            {categories.map((item, index) => (
                                                                <MenuItem value={item.id} key={index}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Field
                                                            fullWidth
                                                            name="number"
                                                            size="small"
                                                            defaultValue={data.number}
                                                            component={TextField}
                                                            label={"Room number"}
                                                            type="number"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                            <div className="right_left">
                                                <Button variant="contained" color="error" sx={{ mt: 3, mb: 2 }} onClick={handleClose}>
                                                    {"Close"}
                                                </Button>
                                                {loading ? (
                                                    <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        {"Submit"}
                                                    </LoadingButton>
                                                ) : (
                                                    <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        {"Submit"}
                                                    </Button>
                                                )}
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </Box>
                    )}
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
