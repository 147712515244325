import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import ENV from "../../environment";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { Button, Input } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportFilter, setRHotel, setRType } from "../../store/report-filer";
import FileSaver from "file-saver";
import Spinner from "react-spinner-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const types = ["Monthly", "Half-monthly", "Custom"];

const BigCalendar = () => {
    const dispatch = useDispatch();
    const reportFilter = useSelector(getReportFilter);
    const [hotels, setHotels] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState(reportFilter.rhotel);
    const [selectedType, setSelectedType] = useState(reportFilter.rbtype);
    const [variantLoading, setVariantLoading] = useState(false);
    const [variants, setVariants] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState(null);

    const getHotels = async () => {
        await axios(`${ENV.URL}/api/hdp/tts/adm/hotel`, {
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => setHotels(res.data.data.hotels))
            .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
    };

    const getVariants = async (type) => {
        setVariantLoading(true);
        await axios
            .get(`${ENV.URL}/api/hdp/tts/adm/hotel/${selectedHotel}/check/booking/${type}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => setVariants(res.data.data))
            .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
        setVariantLoading(false);
    };

    const updateVariants = async () => {
        switch (selectedType) {
            case "Monthly":
                getVariants("oylik");
                break;
            case "Half-monthly":
                getVariants("yarim oylik");
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (selectedHotel && selectedType) updateVariants();
    }, [selectedHotel]);

    useEffect(() => {
        if (selectedHotel && selectedType) updateVariants();
    }, [selectedType]);

    const changeSelectHotel = async (e) => {
        setSelectedHotel(e.target.value);
        setSelectedVariant(null);
        setVariants([]);
        dispatch(setRHotel(e.target.value));
    };

    const changeSelectType = async (e) => {
        setSelectedType(e.target.value);
        dispatch(setRType(e.target.value));
    };

    const changeSelectDate = async (e) => {
        setSelectedVariant(e.target.value);
    };

    useEffect(() => {
        getHotels();
    }, []);

    const [file, setFile] = useState(null);
    const [src, setSrc] = useState(null);
    const [downloadReport, setDownloadReport] = useState(false);

    function getReportFile() {
        setDownloadReport(true);
        fetch(`${ENV.URL}/api/hdp/tts/adm/hotel/${selectedHotel}/check/booking/doc/${selectedVariant}`, {
            method: "GET",
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => res.blob())
            .then((blob) => {
                setFile(blob);
                setSrc(window.URL.createObjectURL(blob));
                setDownloadReport(false);
            })
            .catch((err) => {
                console.log(err);
                setDownloadReport(false);
            });
    }

    function download() {
        FileSaver.saveAs(file, `${selectedVariant}_${new Date().getTime()}`);
    }

    const defaultDate = dayjs(new Date().toISOString().slice(0, 10));
    const [startDate, setStartDate] = useState(dayjs(new Date().toISOString().slice(0, 8) + "01"));
    const [endDate, setEndDate] = useState(defaultDate);
    const onChangeStartDate = (e) => setStartDate(e);
    const onChangeEndDate = (e) => setEndDate(e);

    function getReportFile2() {
        setDownloadReport(true);
        const searchParams = new URLSearchParams();
        searchParams.append("start_date", startDate.tz("Asia/Tashkent").format("YYYY-MM-DD"));
        searchParams.append("end_date", endDate.tz("Asia/Tashkent").format("YYYY-MM-DD"));
        searchParams.append("name", startDate.tz("Asia/Tashkent").format("DD.MM.YYYY") + " - " + endDate.tz("Asia/Tashkent").format("DD.MM.YYYY"));

        fetch(`${ENV.URL}/api/hdp/tts/adm/hotel/${selectedHotel}/check/booking/generate?${searchParams.toString()}`, {
            method: "GET",
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
        })
            .then((res) => res.blob())
            .then((blob) => {
                setFile(blob);
                setSrc(window.URL.createObjectURL(blob));
                setDownloadReport(false);
            })
            .catch((err) => {
                console.log(err);
                setDownloadReport(false);
            });
    }
    
    return (
        <div id="big_cal" className="container py-5">
            <div className="my-calendar">
                <Box sx={{ minWidth: 120, marginBottom: 2 }} className="row g-5 mb-4">
                    <div className="col-md-2">
                        <FormControl fullWidth size="small">
                            <InputLabel>{"Hotel"}</InputLabel>
                            <Select value={selectedHotel} label="Hotel" onChange={changeSelectHotel}>
                                {hotels.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-2">
                        <FormControl fullWidth size="small">
                            <InputLabel>{"Report Type"}</InputLabel>
                            <Select label="Report Type" value={selectedType} onChange={changeSelectType}>
                                {types.map((item) => (
                                    <MenuItem value={item} key={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {selectedType === "Custom" ? (
                        <React.Fragment>
                            <div className="col-md-3 report-date">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]} fullWidth size="small">
                                        <DatePicker label="Start date" value={startDate} onChange={onChangeStartDate} maxDate={endDate} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className="col-md-3 report-date">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                        <DatePicker label="End date" value={endDate} minDate={startDate} onChange={onChangeEndDate} maxDate={defaultDate} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="col-md-6">
                            <FormControl size="small" style={{ minWidth: "220px" }}>
                                <InputLabel>{"Report Date"}</InputLabel>
                                <Select
                                    label="Report Date"
                                    value={selectedVariant}
                                    disabled={!selectedHotel || !selectedType || variantLoading}
                                    onChange={changeSelectDate}
                                >
                                    {variants.map((item) => (
                                        <MenuItem value={item.name} key={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    <div className="col-md-2">
                        {selectedType === "Custom" ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={getReportFile2}
                                disabled={!startDate || !endDate || downloadReport || !selectedHotel}
                            >
                                view report
                            </Button>
                        ) : (
                            <Button variant="contained" color="primary" onClick={getReportFile} disabled={!selectedVariant || downloadReport}>
                                view report
                            </Button>
                        )}
                    </div>
                </Box>
                {downloadReport ? (
                    <div style={{ margin: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                    </div>
                ) : (
                    src && <iframe src={src} title="Report" frameborder="0" style={{ width: "100%", height: "600px" }} />
                )}
            </div>
        </div>
    );
};

export default BigCalendar;
