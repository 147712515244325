import React from "react";
import Cities from "../components/common";

const CitiesPage = () => {
    return (
        <>
            <Cities />
        </>
    );
};

export default CitiesPage;
