import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import axios from "axios";
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CancelIcon from '@mui/icons-material/Cancel';
import ENV from "../../../environment";
import Spinner from "react-spinner-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const HotelPolicy = () => {
  const { hotelid } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getData = async () => {
    await axios
      .get(`${ENV.URL}/api/hdp/tts/adm/hotel/policy/${hotelid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token"),
          "client-id": ENV.CLIENTID,
          "client-secret": ENV.SECRET,
        },
      })
      .then((res) => {
        setLoadingData(false);
        setData(res.data.data.data);
      })
      .catch((err) => {
        setLoadingData(false);
        toast.error(
          err?.response?.data?.errors[0]?.message || "An error occurred"
        );
      });
  };

  const validate = Yup.object({
    check_in_time: Yup.string().required("Field is required"),
    check_out_time: Yup.string().required("Field is required"),
    free_wifi: Yup.string().required("Field is required"),
    kids_stay_free: Yup.string().required("Field is required"),
    free_parking: Yup.string().required("Field is required"),
    allow_pets: Yup.string().required("Field is required"),
    hourbypay: Yup.string().required("Field is required"),
    citizenship: Yup.string().required("Field is required"),
  });
  const emptyData = {
    check_in_time: data?.check_in_time ? data.check_in_time : null,
    check_out_time: data?.check_out_time ? data.check_out_time : null,
    free_wifi: data?.free_wifi ? data.free_wifi : false,
    kids_stay_free: data?.kids_stay_free ? data.kids_stay_free : false,
    free_parking: data?.free_parking ? data.free_parking : false,
    allow_pets: data?.allow_pets ? data.allow_pets : false,
    hourbypay: data?.hourbypay ? data.hourbypay : false,
    citizenship: data?.citizenship ? data.citizenship : false,
  };

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const body = {
      check_in_time: values?.check_in_time,
      check_out_time: values?.check_out_time ,
      free_wifi: values?.free_wifi,
      kids_stay_free: values?.kids_stay_free,
      free_parking: values?.free_parking,
      allow_pets: values?.allow_pets,
      hourbypay: values?.hourbypay,
      citizenship: values?.citizenship,
    };
        if(!data?.check_in_time){
          await axios
          .post(`${ENV.URL}/api/hdp/tts/adm/hotel/policy/${hotelid}`, body, {
            headers: {
              "Content-Type": "application/json",
              Authorization: sessionStorage.getItem("token"),
              "client-id": ENV.CLIENTID,
              "client-secret": ENV.SECRET,
            },
          })
          .then((res) => {
            setLoading(false);
            getData();
            toast.success("Saved successfully", { autoClose: 2000 });
            resetForm({ values: "" });
            handleClose();
          })
          .catch((err) => {
            setLoading(false);
            toast.error(
              err?.response?.data?.errors[0]?.message || "An error occurred"
            );
          });
        }
        await axios
        .put(`${ENV.URL}/api/hdp/tts/adm/hotel/policy/${hotelid}`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("token"),
            "client-id": ENV.CLIENTID,
            "client-secret": ENV.SECRET,
          },
        })
        .then((res) => {
          setLoading(false);
          getData();
          toast.success("Saved successfully", { autoClose: 2000 });
          resetForm({ values: "" });
          handleClose();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(
            err?.response?.data?.errors[0]?.message || "An error occurred"
          );
        });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loadingData ? (
            <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
        </div>
      ) : (
        <div className="card container p-0 mt-3">
          <div className="card-header d-flex justify-content-center">
            <h5 className="card-title text-primary">{"List of policies"}</h5>
          </div>
          <div className="card-body">
            {
              data?.check_in_time ? 
            <div className="list-group">
          <button type="button" className="list-group-item list-group-item-action" disabled>Check in time :  {data?.check_in_time?.slice(0,5)}</button>
          <button type="button" className="list-group-item list-group-item-action" disabled>Check out time : {data?.check_out_time?.slice(0,5)}</button>
          <button type="button" className="list-group-item list-group-item-action" disabled>Free Wi-Fi : {data?.free_wifi === true ? <span className="text-success">WiFi is available in public areas and is free of charge. <DownloadDoneIcon/></span> : <s className="text-danger">WiFi is available in public areas and is free of charge. <CancelIcon/></s>}</button>
          <button type="button" className="list-group-item list-group-item-action" disabled>Kids stay free : {data?.kids_stay_free === true ? <span className="text-success">Kids stay free. <DownloadDoneIcon/></span> : <s className="text-danger">Kids stay free. <CancelIcon/></s>}</button>
          <button type="button" className="list-group-item list-group-item-action" disabled>Free parking : {data?.free_parking === true ? <span className="text-success">Free parking. <DownloadDoneIcon/></span> : <s className="text-danger">Free parking. <CancelIcon/></s>}</button>
          <button type="button" className="list-group-item list-group-item-action" disabled>Allow pets : {data?.allow_pets === true ? <span className="text-success">Allow pets. <DownloadDoneIcon/></span> : <s className="text-danger">Allow pets. <CancelIcon/></s>}</button>
          <button type="button" className="list-group-item list-group-item-action" disabled>Hour by pay : {data?.hourbypay === true ? <span className="text-success">Hour by pay. <DownloadDoneIcon/></span> : <s className="text-danger">Hour by pay. <CancelIcon/></s>}</button>
          <button type="button" className="list-group-item list-group-item-action" disabled>Citizenship : {data?.citizenship === true ? <span className="text-success">Citizenship. <DownloadDoneIcon/></span> : <s className="text-danger">Citizenship. <CancelIcon/></s>}</button>
          </div>
          :
          <div className="alert alert-warning" role="alert">
                   No policies listed at this time!
          </div>
          }
            <Button
              className="float-end mt-3"
              variant="contained"
              color="primary"
              onClick={handleOpen}
            >
              {data?.check_in_time ? "Update Policy" : "Add Policy"}
            </Button>
          </div>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="modal_res">
            <>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                className="marginBottom"
              >
                {"Enter hotel info"}
              </Typography>
              <Formik
                initialValues={emptyData}
                validationSchema={validate}
                onSubmit={async (values, { resetForm }) => {
                  onSubmit(values, { resetForm });
                  return new Promise((res) => setTimeout(res, 500));
                }}
              >
                {({ values, errors }) => (
                  <Form autoComplete="off" className="mt-4">
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="check_in_time"
                          size="small"
                          component={TextField}
                          type="time"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="check_out_time"
                          size="small"
                          component={TextField}
                          type="time"
                        />
                      </Grid>
                      <Grid item xs={6}>
                                        <Field name="free_wifi" as="div">
                                            {({ field }) => (
                                                <>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Free Wi-Fi</FormLabel>
                                                        <RadioGroup row {...field} >
                                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field name="kids_stay_free" as="div">
                                            {({ field }) => (
                                                <>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Kids stay free</FormLabel>
                                                        <RadioGroup row {...field}>
                                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Field name="free_parking" as="div">
                                            {({ field }) => (
                                                <>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Free parking</FormLabel>
                                                        <RadioGroup row {...field}>
                                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6} >
                                    <Field name="allow_pets" as="div">
                                            {({ field }) => (
                                                <>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Allow pets</FormLabel>
                                                        <RadioGroup row {...field}>
                                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Field name="hourbypay" as="div">
                                            {({ field }) => (
                                                <>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Hour by pay</FormLabel>
                                                        <RadioGroup row {...field}>
                                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6} >
                                    <Field name="citizenship" as="div">
                                            {({ field }) => (
                                                <>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Citizenship</FormLabel>
                                                        <RadioGroup row {...field}>
                                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                    </Grid>
                    <div className="right_left">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="error"
                      >
                        {"Close"}
                      </Button>
                      <>
                        {loading ? (
                          <LoadingButton
                            loading
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            {"Submit"}
                          </LoadingButton>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            {"Submit"}
                          </Button>
                        )}
                      </>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
              {loading && <div className="overlay" />}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default HotelPolicy;
