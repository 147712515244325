import AddBookingType from "./AddBookingType";
import MessageProvider from "./Context";
import { useState } from "react";
import Table from "./Table";

const BookingType = () => {
    const [isWaiting, setIsWaiting] = useState(false);
    const [bookingTypes, setBookingType] = useState(false);
    return (
        <div className="container py-5">
            {isWaiting && <div className="ca-overlay"></div>}
            <MessageProvider>
                <AddBookingType bookingTypes={bookingTypes} />
                <Table setIsWaiting={setIsWaiting} setBookingType={setBookingType} />
            </MessageProvider>
        </div>
    )
}

export default BookingType;