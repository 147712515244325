import React from "react";
import Box from "@mui/material/Box";
import HotelTabs from "../components/Hotel/Tabs";
import HotelAmenities from "../components/Hotel/HotelAmenities";

export default function HotelAmenityPage() {
    return (
        <Box sx={{ width: "100%" }}>
            <HotelTabs />
            <HotelAmenities />
        </Box>
    );
}
