import { BrowserRouter as Router } from "react-router-dom";
import { WorkSpaceProvider } from "./context/WorkSpace";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
import axios from "axios";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";
import App from "./App";

axios.interceptors.response.use(
    (response) => {
        // const newToken = response.data.data?.refreshToken;
        // if (newToken) {
        //     delete response.data.data?.refreshToken;
        //     sessionStorage.setItem("token", newToken);
        // }
        return response;
    },
    (error) => {
        if (error?.response?.status === 499) {
            sessionStorage.clear();
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <WorkSpaceProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </WorkSpaceProvider>
    </Router>
);
