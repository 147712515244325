import React, { useContext } from "react";
import { Message } from "./Context";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import REACT_APP from "../../environment";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function GenerateLicensePartner({ id, handleMenuClose, setIsWaiting, getAllHotels }) {
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [file, setFile] = React.useState("");

    const onSubmit = async () => {
        const data = new FormData();
        data.append("hotel_id", id);
        data.append("end_date", new Date(new Date().getFullYear() + 10, 0, 0).toISOString());
        data.append("file", file);

        setLoading(true);
        setIsWaiting(true);
        await axios
            .post(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/license`, data, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then(async (res) => {
                await getAllHotels();
                setIsWaiting(false);
                setLoading(false);
                toast.success("Success!", { autoClose: 2000 });
                handleClose();
                handleMenuClose();
            })
            .catch((err) => {
                setLoading(false);
                setIsWaiting(false);
                console.log(err);
                toast.error((err?.response?.data?.errors && err?.response?.data?.errors[0]?.message) || "An error occurred");
            });
    };

    return (
        <div>
            <Button onClick={handleOpen}>Generate License</Button>
            <Modal
                aria-labelledby="transition-modal-title-2"
                aria-describedby="transition-modal-description-2"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title-2" variant="h6" component="h2" className="marginBottom">
                            Generate License
                        </Typography>
                        <Grid item xs={12} className="marginBottom mt-4">
                            <Button type="submit" variant="contained" disabled={loading}>
                                Choose agreement File
                                <input
                                    disabled={loading}
                                    id="hide-file-input"
                                    type="file"
                                    accept="application/pdf"
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </Button>
                            <span>&nbsp; &nbsp;{file.name}</span>
                        </Grid>
                        <div className="left_right">
                            <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                Close
                            </Button>
                            {loading ? (
                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                    submit
                                </LoadingButton>
                            ) : (
                                <Button
                                    type="submit"
                                    className="mt-5"
                                    disabled={!file}
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    color="success"
                                    onClick={onSubmit}
                                >
                                    submit
                                </Button>
                            )}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
