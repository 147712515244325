import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import REACT_APP from "../../environment.js";
import Spinner from "react-spinner-material";
import Alert from "@mui/material/Alert";
import { Message } from "./Context.js";
import Action from "./Action.js";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { getHotelFilter, setHFOrderBy, setHFPage, setHFPartner, setHFReset, setHFSearch, setHFSortBy, setHFStatus } from "../../store/hotel-table-filer.js";

const headCells = [
    { id: "id", numeric: false, label: "Id", align: "left" },
    { id: "name", numeric: true, label: "Name", align: "left" },
    { id: "regionname", numeric: true, label: "Region", align: "center" },
    {
        id: "action",
        numeric: true,
        label: "Actions",
        align: "center",
        renderCell: () => {
            return <></>;
        },
    },
];

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align}>
                        {headCell.id === "action" && <>&nbsp; &nbsp;&nbsp; &nbsp;</>}
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = { rowCount: PropTypes.number.isRequired };

export default function EnhancedTable({ setIsWaiting, setRegiondata }) {
    const [message] = useContext(Message);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [regionvalue, setregionvalue] = React.useState(null);

    const [data, setData] = React.useState([]);
    const [regions, setregion] = React.useState([]);
    const [dataLength, setDataLength] = React.useState(0);

    const dispatch = useDispatch();
    const hotelFiler = useSelector(getHotelFilter);
    const rowsPerPage = 10;
    const [page, setPage] = React.useState(hotelFiler.page);
    const [forderby, setforderby] = useState(hotelFiler.forderby);

    
    const changeFOrderBy = (event) => {
        setregionvalue(event.target.value);
        console.log((event.target.value))
        setforderby(event.target.value);
    };

    const getHotels = async () => {
        setLoading(true);
        const searchParams = new URLSearchParams();
        searchParams.append("page", page);
        searchParams.append("limit", rowsPerPage);

        await axios
            .get(`${REACT_APP.COMMON_API}/tts/api/v01/cities`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.COMMON_ID, "secret": REACT_APP.COMMON_SECRET },
            })
            .then((res) => {
                setData(res.data.data);
                setDataLength(res.data.data.length);
                setLoading(false);
                setError(false);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
        await axios
            .get(`${REACT_APP.COMMON_API}/tts/api/v01/regions`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.COMMON_ID, "secret": REACT_APP.COMMON_SECRET },
            })
            .then((res) => {
                setregion(res.data.data);
                setRegiondata(res.data.data)
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
    };
    const filterregions = async () => {
        console.log(regionvalue)
        if(!regionvalue){
            getHotels()
        }
        else{
        setLoading(true);
            await axios
            .get(`${REACT_APP.COMMON_API}/tts/api/v01/oneregioncities/${regionvalue}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.COMMON_ID, "secret": REACT_APP.COMMON_SECRET },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
                setDataLength(res.data.data.length)
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
        }
    }
    const handleChangePage = (event, newPage) => {
        dispatch(setHFPage(newPage));
        setPage(newPage);
    };

    React.useEffect(() => {
        getHotels();
    }, [message]);

    React.useEffect(() => {
        getHotels();
    }, [page]);

    return (
        <React.Fragment>
            <div style={{ display: "flex", alignItems: "baseline", flexWrap: "wrap", marginBottom: "10px" }}>


                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} disabled={loading}>
                    <InputLabel id="order-by-label">Region</InputLabel>
                    <Select
                        labelId="order-by-label"
                        id="order-by-label"
                        value={forderby}
                        onChange={changeFOrderBy}
                        label="Region"
                    >
                        {regions.map((region) => (
                            <MenuItem key={region.id} value={region.id}>
                                {region.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" className="mx-4" size="small" onClick={filterregions} disabled={loading}>
                    Filter
                </Button>
            </div>
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%" }}>
                    {!error && data.length === 0 && (
                        <Alert variant="outlined" severity="warning">
                            No hotel found
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            Could not get data
                        </Alert>
                    )}
                    {data.length >= 1 && (
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead rowCount={data.length} />
                                    <TableBody>
                                        {data.map((row) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={row.id}>
                                                    <TableCell align="left">{row.id}</TableCell>
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell align="center">{row.regionname}</TableCell>
                                                    <TableCell align="center">
                                                        <Action
                                                            id={row.id}
                                                            status={row.status}
                                                            data={row}
                                                            name={row.name}
                                                            in_hbp={row.in_hbp}
                                                            setIsWaiting={setIsWaiting}
                                                            getAllHotels={getHotels}
                                                            regions={regions}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={dataLength}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </Paper>
                    )}
                </Box>
            )}
        </React.Fragment>
    );
}
