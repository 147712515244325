import React from "react";
import styles from "./styles.module.css";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PlaceIcon from "@mui/icons-material/Place";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
const ProviderContents = ({ data }) => {
    return (
        <div className={styles.boxContainer}>
            <div className={styles.box}>
                <div className={styles.boxHead}>
                    <HomeWorkIcon /> {data.name}
                </div>
                <div className={styles.boxBody}>
                    <div className={styles.boxKeyValue}>
                        <div className={styles.boxKey}>name:&nbsp;</div>
                        <div className={styles.boxValue}>{data.name}</div>
                    </div>
                    <div className={styles.boxKeyValue}>
                        <div className={styles.boxKey}>status:&nbsp;</div>
                        <div className={styles.boxValue}>{data.provider_status}</div>
                    </div>
                    <div className={styles.boxKeyValue}>
                        <div className={styles.boxKey}>email:&nbsp;</div>
                        <div className={styles.boxValue}>{data.email}</div>
                    </div>
                    <div className={styles.boxKeyValue}>
                        <div className={styles.boxKey}>website:&nbsp;</div>
                        <div className={styles.boxValue}>{data.website || "no"}</div>
                    </div>
                    <div className={styles.boxKeyValue}>
                        <div className={styles.boxKey}>users:&nbsp;</div>
                        <div className={styles.boxValue}>{data.number_of_users}</div>
                    </div>
                    <div className={styles.boxKeyValue}>
                        <div className={styles.boxKey}>products:&nbsp;</div>
                        <div className={styles.boxValue}>{data.number_of_products}</div>
                    </div>
                    <div className={styles.boxKeyValue}>
                        <div className={styles.boxKey}>product types:&nbsp;</div>
                        <div className={styles.boxValue}>{data.number_of_product_types}</div>
                    </div>
                    <div className={styles.boxKeyValue}>
                        <div className={styles.boxKey}>contacts:&nbsp;</div>
                        <div className={styles.boxValue}>{data.contacts?.length || 0}</div>
                    </div>
                </div>
            </div>
            <div className={styles.box}>
                <div className={styles.boxHead}>
                    <CurrencyExchangeIcon /> Bank info
                </div>
                <div className={styles.boxBody}>
                    {!data.bank_info ? (
                        <div className={styles.boxField}>bank account information not found</div>
                    ) : (
                        <React.Fragment>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>supervisor:&nbsp;</div>
                                <div className={styles.boxValue}>{data.bank_info.supervisor}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>chief accountant:&nbsp;</div>
                                <div className={styles.boxValue}>{data.bank_info.chief_accountant}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>bank account:&nbsp;</div>
                                <div className={styles.boxValue}>{data.bank_info.bank_account}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>bank mfo:&nbsp;</div>
                                <div className={styles.boxValue}>{data.bank_info.bank_mfo}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>tin:&nbsp;</div>
                                <div className={styles.boxValue}>{data.bank_info.TIN}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>qqs register code:&nbsp;</div>
                                <div className={styles.boxValue}>{data.bank_info.qqs_register_code}</div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className={styles.box}>
                <div className={styles.boxHead}>
                    <PlaceIcon /> Address
                </div>
                <div className={styles.boxBody}>
                    {!data.address ? (
                        <div className={styles.boxField}>legal address information not found</div>
                    ) : (
                        <React.Fragment>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>country:&nbsp;</div>
                                <div className={styles.boxValue}>{data.address.country}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>region:&nbsp;</div>
                                <div className={styles.boxValue}>{data.address.region}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>city:&nbsp;</div>
                                <div className={styles.boxValue}>{data.address.city}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>address1:&nbsp;</div>
                                <div className={styles.boxValue}>{data.address.address1}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>address2:&nbsp;</div>
                                <div className={styles.boxValue}>{data.address.address2}</div>
                            </div>
                            <div className={styles.boxKeyValue}>
                                <div className={styles.boxKey}>zip code:&nbsp;</div>
                                <div className={styles.boxValue}>{data.address.zip_code}</div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProviderContents;
