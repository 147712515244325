import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: { rhotel: "", rbtype: "Monthly" } };

export const reportFilerSlice = createSlice({
    name: "result",
    initialState,
    reducers: {
        setRHotel: (state, action) => {
            state.value.rhotel = action.payload;
        },
        setRType: (state, action) => {
            state.value.rbtype = action.payload;
        },
    },
});

export const { setRHotel, setRType } = reportFilerSlice.actions;

export const getReportFilter = (state) => {
    return state.reportfilter.value;
};

export default reportFilerSlice.reducer;
