/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { TextField } from "formik-material-ui";
import Backdrop from "@mui/material/Backdrop";
import { Field, Form, Formik } from "formik";
import Button from "@mui/material/Button";
import REACT_APP from "../../environment";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import Fade from "@mui/material/Fade";
import { Grid } from "@mui/material";
import { Message } from "./Context";
import Box from "@mui/material/Box";
import regex from "tts-hrp-val-lib";
import * as Yup from "yup";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function UpdateCities({ id, data, handleMenuClose, getAllHotels, regions }) {
    const [, setMessage] = useContext(Message);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validation = Yup.object({
        name: Yup.string().required("Name is required").matches(regex.text, { message: "Name is invalid" })
    });

    const onUpdate = async (values) => {
        let { id, name } = values;
        const updateData = { id, name };
        setMessage(true);
        try {
            await axios.put(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/booking-type/${id}`, updateData, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            });
            setMessage(false);
            toast.success("Success!", { autoClose: 2000 });
            handleClose();
            handleMenuClose();
        } catch (err) {
            setLoading(false);
            console.error(err);
            toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
        }
    };

    const keyUp = (e) => {
        console.log(e.keyCode);
    };

    useEffect(() => {
        document.addEventListener("keyup", keyUp);
        return () => {
            document.removeEventListener("keyup", keyUp);
        };
    }, []);

    return (
        <div>
            <Button onClick={handleOpen}>Update</Button>
            <Modal
                aria-labelledby="transition-modal-title-2"
                aria-describedby="transition-modal-description-2"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title-2" variant="h6" component="h2" className="marginBottom">
                            Enter booking type information
                        </Typography>
                        <Formik initialValues={data} enableReinitialize validationSchema={validation} onSubmit={onUpdate}>
                            {({ values, errors, dirty }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field fullWidth label="Name" name="name" component={TextField} type="text" />
                                        </Grid>
                                    </Grid>
                                    <div className="left_right">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                            Close
                                        </Button>
                                        {loading ? (
                                            <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Update
                                            </LoadingButton>
                                        ) : (
                                            <Button className="mt-5" disabled={!dirty || errors?.name} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                Update
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
