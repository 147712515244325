import React from "react";
import Library from "../components/LibraryFile/index";

const LibraryPage = () => {
    return (
        <>
            <Library />
        </>
    );
};

export default LibraryPage;
