import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: { fsearch: "", fstatus: 0, fpartner: 0, fsortby: "asc", forderby: "id", page: 0 } };

export const hotelFilerSlice = createSlice({
    name: "result",
    initialState,
    reducers: {
        setHFSearch: (state, action) => {
            state.value.fsearch = action.payload;
        },
        setHFStatus: (state, action) => {
            state.value.fstatus = action.payload;
        },
        setHFPartner: (state, action) => {
            state.value.fpartner = action.payload;
        },
        setHFSortBy: (state, action) => {
            state.value.fsortby = action.payload;
        },
        setHFOrderBy: (state, action) => {
            state.value.forderby = action.payload;
        },
        setHFPage: (state, action) => {
            state.value.page = action.payload;
        },
        setHFReset: (state) => {
            state.value = { ...initialState.value };
        },
    },
});

export const { setHFSearch, setHFStatus, setHFPartner, setHFSortBy, setHFOrderBy, setHFPage, setHFReset } = hotelFilerSlice.actions;

export const getHotelFilter = (state) => {
    return state.hotelfilter.value;
};

export default hotelFilerSlice.reducer;
